import React, { useState, useEffect } from 'react'

import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {  faList, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

// import { headRequest } from '../../../func/funcColl';
import axios from 'axios';


const Tetkikler = () => {


    

    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    
    const [pageLoading, setpageLoading] = useState(false)

    const [data, setdata] = useState([])
    const [search, setSearch] = useState("");
    const [FilteredCountries, setFilteredCountries] = useState([]);

    useEffect(() => {

        SayfaKatDoldur();
    }, [])

    useEffect(() => {
        setFilteredCountries(
            data.filter((country) =>
                country.tetkik.dalAdi.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, data]);



    function SayfaKatDoldur() {

        let fetchurl = "";





        let gelen = (headRequest);
        console.log(gelen);
        // ,setdata(res.data)
        axios.get("/webapi/TetkikList", gelen).then(res => { console.log(res); setdata(res.data); setpageLoading(true); });

        setpageLoading(true)

    }





    return (

        <div>

            <div className="card-header card-header-primary">

                <h4 className="card-title">Tetkikler</h4>

            </div>

            <hr />

            <div className="card-body">

                <div className="card-body">
                    <div className="form-group">
                        <div className="col-lg-12">

                            <input className="form-control"
                                type="text"
                                placeholder="Ara"
                                onChange={(e) => setSearch(e.target.value)} />

                        </div>
                    </div>
                </div>
                {pageLoading &&



                    <div className="table-responsive">

                        <table className="table table-striped">
                            <thead className="">
                                <tr>
                                    <th>
                                        Tetkik Ad
                                    </th>
                                    <th>
                                        Toplam
                                    </th>


                                    <th>
                                        İşlemler
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {a.sf.ana ? <span>Ana sayfa</span> : <span>-</span>} */}
                                {FilteredCountries.map(a =>
                                    <tr key={a.tetkik.id}>

                                        <td>
                                            {a.tetkik.dalAdi}
                                        </td>
                                        <td>
                                            {a.toplam}
                                        </td>

                                        <td>
                                            <ul className="icons-list">
                                                <li className="dropdown dropup ">

                                                    <Link to="#" data-toggle="dropdown" >
                                                        <FontAwesomeIcon className="material-icons" icon={faList}  > </FontAwesomeIcon>
                                                    </Link>

                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><Link to={"tetkik-detay-" + a.tetkik.id}>Detay</Link></li>


                                                        {/* */}

                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>)}

                            </tbody>
                        </table>
                    </div >
                }

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor...  </div>}





            </div >
        </div >

    )



}

export default Tetkikler
