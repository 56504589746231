
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom"

import { useDispatch } from "react-redux";

import { userLogin } from "../../redux/redux";

import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap';

import './login.css'
import { post } from 'jquery';
import axios from 'axios';



const Login = () => {

    let history = useHistory();
    const serialized = localStorage.getItem('ykyon');
    try {
        if (serialized !== null) {
            history.push("");
        }
    } catch { }

    // const yoncc = yoncc

    const [yonKad, setyonKad] = useState('');
    const [yonSifre, setyonSifre] = useState('');
    const [klinikid, setklinikid] = useState();


    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false)



    const submit = (e) => {

        e.preventDefault();

        let deger = true; let gidecekMesaj = "";

        if (yonKad.trim().length === 0 || yonSifre.trim().length === 0) {

            gidecekMesaj += "Klinik Id ,email ve şifrenizi girin."; deger = false;

        }


        if (deger) {
            setIsLoading(true);


            const requestOptions = {
                Method: post, headers: { 'Content-Type': 'application/json' }
            };


            const frdata = new FormData();

            frdata.append('klinikId', klinikid);
            frdata.append('email', yonKad);
            frdata.append('sifre', yonSifre);



            axios.post("/webapi/Login", frdata, requestOptions).then(
                (response) => {


                    setIsLoading(true);
                    localStorage.clear();

                    localStorage.setItem('ykyon', JSON.stringify(response.data));

                    (dispatch(userLogin({
                        klinikid,
                        yonSifre
                    })).then(() => {

                        history.push("/index");
                    }));

                }




            ).catch(error => {
                alert("Kullanıcı adı veya şifre hatalı");
                setyonKad('');
                setyonSifre('');
                setklinikid('')
                setIsLoading(false);
            });


        } else {
            alert(gidecekMesaj); setIsLoading(false);


        }





    }






    return (
        <div className='AdminLoginControl'>
            <div className=' wrapper fadeInDown'   >

                <div id='formContent'  >

                    <div className='fadeIn first'   >
                        <img
                            src='/images/logo.png'
                            id="icon"
                            alt="Logo"
                            style={{ width: 200 }}
                        />
                    </div>

                    <form onSubmit={submit} >

                        <h1>Giriş</h1>

                        <input onChange={e => setklinikid(e.target.value)} value={klinikid} type="text" id="klinikid" placeholder="Klinik Id" className='fadeIn second' name="login"></input>


                        <input onChange={e => setyonKad(e.target.value)} value={yonKad} type="text" id="login" placeholder="Email" className='fadeIn second' name="login"></input>

                        <input onChange={e => setyonSifre(e.target.value)} value={yonSifre} type="password" id="password" className='fadeIn third' name="login" placeholder="Şifre"></input>


                        {isLoading &&
                            <Button color="primary" size="lg" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz... </Button>}


                        {!isLoading && <Button color="primary" size="lg"  >Giriş  </Button>}




                    </form>

                    {/* <div id='formFooter'  >
                        <Link className='underlineHover ' to="/forpassword">Şifremi Unuttum?</Link>
                    </div> */}
                </div>
            </div>
        </div >


    )
}


export default Login;




// export default withRouter(Login);





