import React, { useState, useEffect } from 'react'

import { Link, useHistory, useParams } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt, faList, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import { getFormattedPrice    } from '../../../func/funcColl';


import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';

const TetkikDetay = () => {

    

    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    
    let { id } = useParams();

    const [dalAdi, setdalAdi] = useState('');
    const [pageLoading, setpageLoading] = useState(false)
    const [isleniyor, setisleniyor] = useState(false)
    const [data, setdata] = useState([])



    const [isim, setisim] = useState('');
    const [ucret, setucret] = useState(0);
    const [durum, setdurum] = useState(1);

    const [secilenid, setsecilenid] = useState(0);
    const [secilenKatAd, setsecilenKatAd] = useState('');



    const [showGuncelle, setshowGuncelle] = useState(false);
    const [show, setShow] = useState(false);



    const GuncAc = (gelen) => {
        console.log(gelen)
        setisleniyor(false); setshowGuncelle(true); setShow(false);
        setsecilenid(gelen.id); setsecilenKatAd(gelen.isim);
        setisim(gelen.ismi); setucret(gelen.ucreti); setdurum(gelen.durum);
    }



    const handleClose = () => {
        setisleniyor(false); setShow(false); setshowGuncelle(false); setisim(''); setucret(0);
    }

    const handleShow = () => {
        setisleniyor(false); setShow(true); setshowGuncelle(false); setisim(''); setucret(0);
    }



    useEffect(() => {
        SayfaKatDoldur();
    }, [])






    const lattguncelle = () => {

        setisleniyor(true)

        if (isim.length < 2) {
            alert("Tetkik bilgilerinizi girin.");
        }

        const frdata = new FormData();

        frdata.append('isim', isim);
        frdata.append('ucret', ucret);
        frdata.append('durum', durum);


        axios.post("/webapi/TetkikGunc-" + secilenid, frdata, headRequest).then(
            (res) => {

                console.log(res)
                setdalAdi(res.data.tetkik.dalAdi)
                setdata(res.data.liste);

                handleClose()

            }
        ).catch((error) => {
            alert(error);
            handleClose();
            SayfaKatDoldur()
        });


    }




    const lattEkle = () => {

        setisleniyor(true)

        if (isim.length < 2) {
            alert("Tetkik bilgilerinizi girin.");
        }

        const frdata = new FormData();

        frdata.append('isim', isim);
        frdata.append('ucret', ucret);
        frdata.append('durum', durum);
        frdata.append('dali', id);


        axios.post("/webapi/TetkikEkle", frdata, headRequest).then(
            (res) => {

                console.log(res)
                setdalAdi(res.data.tetkik.dalAdi)
                setdata(res.data.liste);

                handleClose()

            }
        ).catch((error) => {
            alert(error);
            handleClose();
            SayfaKatDoldur()
        });


    }





    function SayfaKatDoldur() {

        setpageLoading(false);

        fetch("/webapi/TetkikListDetay-" + id, headRequest).then(res => res.json()).then(res => {

            console.log(res)
            setdalAdi(res.tetkik.dalAdi)
            setdata(res.liste);

        });

        setpageLoading(true)

    }





    return (

        <div>
            <div className="card-header card-header-primary">
                <Link to={"Tetkikler"} style={{ margin: "20px" }} >Geri Dön</Link>
                <h4 className="card-title">Tetkik Detay </h4>
            </div>
            <div className="card-body">

                <h3>{dalAdi}</h3>

                <div className="text-right">

                    <Button color="primary" onClick={handleShow} style={{ margin: '10px' }}> Tetkik Ekle </Button>

                </div>

                <hr />

                {pageLoading &&
                    <div className="table-responsive">


                        <table className="table table-striped">
                            <thead className="">
                                <tr>
                                    <th>
                                        Tetkik Adı
                                    </th>
                                    <th>
                                        Ücret
                                    </th>
                                    <th>
                                        Durum
                                    </th>
                                    <th>
                                        İşlemler
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(a => <tr>


                                    <td>
                                        {a.ismi}
                                    </td>
                                    <td>
                                        {getFormattedPrice(a.ucreti)}
                                    </td>
                                    <td>
                                        {a.durum && <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />}
                                        {!a.durum && <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />}
                                    </td>
                                    <td>
                                        <ul className="icons-list">
                                            <li className="dropdown dropup ">

                                                <Link to="#" data-toggle="dropdown" >
                                                    <FontAwesomeIcon className="material-icons" icon={faList}  > </FontAwesomeIcon>
                                                </Link>

                                                <ul className="dropdown-menu dropdown-menu-right">
                                                    <li><a onClick={() => GuncAc(a)}>Detay</a></li>


                                                    {/* */}

                                                </ul>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>)}

                            </tbody>
                        </table>


                    </div>
                }

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}



                <Modal isOpen={show}     >
                    <ModalHeader toggle={handleClose}>Tetkik Ekle</ModalHeader>
                    <ModalBody>

                        <div className="col-md-12">

                            <div className="form-group">
                                <label className="col-lg-3 control-label">Durum *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setdurum(e.target.value)} name="durum" id="durum">
                                        <option value={1} selected={durum === 1} >Aktif</option>
                                        <option value={0} selected={durum === 0} >Pasif</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-12 control-label">Tetkik Adı:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="isim" name="isim" className="form-control" onChange={e => setisim(e.target.value)} value={isim} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-12 control-label">Ücret:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="ucret" name="ucret" className="form-control" onChange={e => setucret(e.target.value)} value={ucret} />
                                </div>
                            </div>


                        </div>

                    </ModalBody>
                    {!isleniyor && <ModalFooter>
                        <Button color="success" onClick={lattEkle}>Kaydet</Button>
                        <Button color="secondary" onClick={handleClose}>İptal</Button>
                    </ModalFooter>}
                    {isleniyor && <ModalFooter>
                        İşleniyor...
                    </ModalFooter>}

                </Modal>









                <Modal isOpen={showGuncelle}   >
                    <ModalHeader toggle={handleClose}>Tetkik Güncelle</ModalHeader>
                    <ModalBody>


                        <div className="col-md-12">



                            <div className="form-group">
                                <label className="col-lg-3 control-label">Durum *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setdurum(e.target.value)} name="durum" id="durum">
                                        <option value={1} selected={durum === true} >Aktif</option>
                                        <option value={0} selected={durum === false} >Pasif</option>
                                    </select>
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-12 control-label">Tetkik Adı:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="isim" name="isim" className="form-control" onChange={e => setisim(e.target.value)} value={isim} />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-12 control-label">Ücret:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="ucret" name="ucret" className="form-control" onChange={e => setucret(e.target.value)} value={ucret} />
                                </div>
                            </div>



                        </div>


                    </ModalBody>
                    {!isleniyor && <ModalFooter>
                        <Button color="success" onClick={lattguncelle}>Güncelle</Button>
                        <Button color="secondary" onClick={handleClose}>İptal</Button>
                    </ModalFooter>}
                    {isleniyor && <ModalFooter>
                        İşleniyor...
                    </ModalFooter>}

                </Modal>







            </div>
        </div>

    )

}

export default TetkikDetay
