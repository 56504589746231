import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck, faList, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons'



import { getFormatDatetime    } from '../../../func/funcColl';
import axios from 'axios';


const Hastalar = () => {


    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    const url = new URL(window.location.href);
    let sayfaid = url.searchParams.get('sayfa');
    const [pageLoading, setpageLoading] = useState(false)



    const [data, setdata] = useState([])
    const [search, setSearch] = useState("");

    const [paramssid, setparamssid] = useState((sayfaid === null ? 1 : sayfaid));
    const [pagelist, setpagelist] = useState([])

    const [toplam, settoplam] = useState(0);
    useEffect(() => {
        SayfaKatDoldur();
    }, [])



    function SayfaKatDoldur() {

        let gelen = (headRequest);

        // ,setdata(res.data)

        let fetchurl = (paramssid === 0 ? 1 : paramssid);



        axios.get("/webapi/HastaList-" + fetchurl, gelen).then(res => {
            console.clear()
            console.log(res.data);
            setdata(res.data.hastaListesi);
            setpageLoading(true);

            settoplam(res.adet);

            setpagelist([]);

            let toplamU = res.data.adet;
            console.log(toplamU)

            let pagelistArray = [];


            if (toplamU < 5) {

                for (let i = 0; i <= toplamU - 1; i++) {

                    pagelistArray.push(i);

                }
            }
            else if (toplamU > 5 && Number(paramssid) < 4) {

                for (let i = 0; i < 5; i++) {

                    pagelistArray.push(i);

                }

            } else if (toplamU > 5 && toplamU < (Number(paramssid) + 2)) {

                let dnn = (toplamU - 5) + (toplamU - Number(paramssid));

                for (let i = dnn - 1; i < toplamU; i++) {
                    pagelistArray.push(i);
                }
            }
            else {
                for (let i = (Number(paramssid) - 3); i < (Number(paramssid) + 2); i++) {

                    pagelistArray.push(i);

                }
            }

            console.log(pagelistArray)

            setpagelist(pagelistArray);






        });

        setpageLoading(true)

    }




    useEffect(() => {

        if (search.length > 2) {

            const frdata = new FormData();

            frdata.append('tc', search);


            axios.post("/webapi/HastaListAra", frdata, headRequest).then(
                (response) => {
                    console.log(response)
                    if (response.status === 200) {


                        setdata(response.data)


                    }
                    else {
                        alert(response.statusText);

                    }

                }
            ).catch(error => alert(error));

        } else {

            SayfaKatDoldur()

        }



    }, [search])





    return (

        <div>


            <div className="card-header card-header-primary">

                <h4 className="card-title">Hastalar</h4>

            </div>
            <div className="text-right">


                <Link color="success" to="hasta-ekle" className="btn btn-success" style={{ margin: "10px" }}> Ekle </Link>
            </div>

            <hr />

            <div className="card-body">

                <div className="card-body">
                    <div className="form-group">
                        <div className="col-lg-12">

                            <input className="form-control"
                                type="text"
                                placeholder="Ara"
                                onChange={(e) => setSearch(e.target.value)} value={search} />

                        </div>
                    </div>
                </div>
                {pageLoading &&



                    <div className="table-responsive">

                        <table className="table table-striped">
                            <thead className="">
                                <tr>
                                    <th>
                                        Ad Soyad
                                    </th>
                                    <th>
                                        Tc
                                    </th>
                                    <th>
                                        Dogum Tarihi
                                    </th>
                                    <th>
                                        Tel
                                    </th>
                                    <th>
                                        Mail
                                    </th>
                                    <th>
                                        tarih
                                    </th>
                                    <th>
                                        İşlem
                                    </th>
                                    <th>
                                        Durum
                                    </th>
                                </tr>
                            </thead>
                            {/* ad soyad tc dogumT adres anneAdi babaAdi telefon mail referans tarih */}

                            <tbody>
                                {/* {a.sf.ana ? <span>Ana sayfa</span> : <span>-</span>} */}
                                {data.map(a =>
                                    <tr key={a.hastaId}>
                                        <td>
                                            {a.ad.toUpperCase()} {a.soyad.toUpperCase()}
                                        </td>
                                        <td>
                                            {a.tc}
                                        </td>
                                        <td>
                                            {getFormatDatetime(a.dogumT)}
                                        </td>
                                        <td>
                                            {a.telefon}
                                        </td>
                                        <td>
                                            {a.mail}
                                        </td>

                                        <td>
                                            {getFormatDatetime(a.tarih)}
                                        </td>
                                        <td>
                                            {a.durum && <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />}
                                            {!a.durum && <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />}
                                        </td>

                                        <td>
                                            <ul className="icons-list">
                                                <li className="dropdown dropup ">

                                                    <Link to="#" data-toggle="dropdown" >
                                                        <FontAwesomeIcon className="material-icons" icon={faList}  > </FontAwesomeIcon>
                                                    </Link>

                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><Link to={"hasta-detay-" + a.hastaId}>Detay</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>)}

                            </tbody>
                        </table>




                        <div>
                            <div className="controls-product-item row">

                                <ul className="pagination  justify-content-center">
                                    <li className="page-item">
                                        <ul className="pagination  justify-content-center">
                                            <li className="page-item">
                                                <Link className="page-link" to={"?sayfa=" + 1} onClick={() => setparamssid(1)} > İlk </Link>
                                            </li>

                                            {pagelist.map(a =>

                                                <li key={a} className={Number(paramssid) === (a + 1) ? "page-item active" : "page-item"} >

                                                    <Link className="page-link" onClick={() => setparamssid(a + 1)} to={"?sayfa=" + (a + 1)}  > {a + 1} </Link>

                                                </li>

                                            )}

                                            <li className="page-item">
                                                <Link className="page-link" onClick={() => setparamssid(toplam)} to={"?sayfa=" + toplam}  > Son </Link>
                                            </li>
                                        </ul>

                                    </li></ul>
                            </div>
                        </div>








                    </div>
                }

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor...  </div>}





            </div >
        </div>
    )
}

export default Hastalar
