import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons'

import { getFormatDatetime, getFormattedPrice   } from '../../../func/funcColl';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
// import Alert from 'reactstrap/lib/Alert';


// VezneListe
const Vezneler = () => {


    

    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };


    const [pageLoading, setpageLoading] = useState(false)
    const [data, setdata] = useState([])

    const [isleniyor, setisleniyor] = useState(true)
    const [show, setshow] = useState(false)
    const [vezne, setvezne] = useState()
    const [ucret, setucret] = useState(0)

    useEffect(() => {

        SayfaKatDoldur();
    }, [])

    function SayfaKatDoldur() {
        axios.get("/webapi/VezneListe", headRequest).then(res => { console.log(res); setdata(res.data); setpageLoading(true) });
        setpageLoading(true)
    }

    const handleClose = () => {
        setshow(false)
        setvezne()
        setucret(0)
    }

    const VezneDetay = (a) => {
        console.clear();
        setisleniyor(true)
        axios.get("/webapi/VezneDetay-" + a, headRequest).then(res => {
            console.log(res);
            setvezne(res.data);
            setisleniyor(false);
            setshow(true)
            setucret(res.data.faturaTutari)
        });

    }

    const odemeYap = () => {
        setisleniyor(true)
        const frdata = new FormData();

        frdata.append('ucret', ucret);

        axios.post("/webapi/VezneFatura-" + vezne.sevk.sevkId, frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {
                    alert("işlendi.");
                    setdata(response.data);
                }
                else {
                    alert(response.statusText);
                    handleClose()
                }
                setshow(false)
                setisleniyor(false)
            }
        ).catch(error =>
            alert(error),
            setshow(false),
            setisleniyor(false)
        );


    }
    return (

        <div>

            <div className="card-header card-header-primary">

                <h4 className="card-title">Vezne</h4>

            </div>

            <hr />

            <div className="card-body">


                {pageLoading &&



                    <div className="table-responsive">

                        <table className="table table-striped">
                            <thead className="">
                                <tr>

                                    <th>
                                        Sevk Tarihi
                                    </th>
                                    <th>
                                        Hasta  Adı
                                    </th>
                                    <th>
                                        Tc
                                    </th>
                                    <th style={{ maxWidth: '50px' }}>
                                        Fatura
                                    </th>
                                    <th>
                                        İşlem
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {a.sf.ana ? <span>Ana sayfa</span> : <span>-</span>} */}
                                {data.map(a =>
                                    <tr key={a.sevk.sevkId}>
                                        <td>
                                            {getFormatDatetime(a.sevk.tarih)}
                                        </td>
                                        <td>
                                           <Link to={"hasta-detay-"+a.hasta.hastaId}>{a.hasta.ad.toUpperCase()} {a.hasta.soyad.toUpperCase()}</Link> 

                                        </td>
                                        <td>
                                            {a.hasta.tc}
                                        </td>

                                        <td style={{ maxWidth: '50px' }}>
                                            {a.fatura === true && <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />}
                                            {!a.fatura && <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} />}
                                        </td>
                                        <td>
                                            {a.fatura === true ?
                                                <strong>{getFormattedPrice(a.faturaTutari)} TL</strong>
                                                :
                                                <button onClick={() => VezneDetay(a.sevk.sevkId)} type='button' className='btn btn-info'> Ödeme Yap [{getFormattedPrice(a.faturaTutari)} TL]</button>
                                            }
                                        </td>



                                    </tr>)}

                            </tbody>
                        </table>
                    </div >
                }

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor...  </div>}





            </div>



            <Modal isOpen={show}>
                <ModalHeader toggle={handleClose}>Hasta Detay</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">


                        {vezne && <>

                            {vezne.fatura === false && <>
                                <h2>{getFormatDatetime(vezne.sevk.tarih)} Tarihli Sevk</h2>

                                <div className="form-group">
                                    <div className="col-lg-12">
                                        <Link className='btn btn-light' style={{ marginRight: "50px" }} to={'hasta-sevkdetay-' + vezne.sevk.sevkId}>Sevk Detay</Link>
                                        <Link className='btn btn-light' to={'hasta-detay-' + vezne.hasta.hastaId}>Hasta Detay</Link>
                                    </div>

                                </div>


                                <div className="form-group">
                                    <label className="col-lg-12 control-label">Ücret:</label>
                                    <div className="col-lg-12">

                                        <input type='text' onChange={(e) => setucret(e.target.value)} value={ucret} className="form-control"></input>

                                    </div>
                                </div></>}

                            {vezne.fatura === true && <>


                            </>}
                        </>}


                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="success" onClick={odemeYap}>Ödemeyi Onayla</Button>
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



        </div>

    )
}

export default Vezneler
