import React, { Component } from 'react';
import { Route, Switch } from 'react-router';


import Indexroute from './components/Indexroute';

import { Provider } from 'react-redux';
import { store } from './redux/redux'
import 'bootstrap/dist/js/bootstrap'
import 'react-calendar/dist/Calendar.css';


export default class App extends Component {
  static displayName = App.name;

  render() {

    return (
      <Switch>
        <Provider store={store}>
          <Route exact path='/' component={Indexroute} />
          <Route exact path='/:all' component={Indexroute} />
        </Provider>


      </Switch>
    );
  }
}
