import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUser, faBell, faDesktop } from '@fortawesome/free-solid-svg-icons'



const NavBar = ({ closeLayer, setcloseLayer  }) => {
 
  // const value = localStorage.getItem("ykyon");

  // const girisler = JSON.parse(JSON.parse(value).payload.token);

  const [count, setcount] = useState(0);

  // async function axiosFunc() {

  //   const requestOptionsyukle = {
  //     method: 'get', headers: { 'Content-Type': 'application/json', Authorization: 'Basic ' + encodeURI(girisler.kad + ":" + girisler.sifre) }
  //   };


  //   await fetch("/adn_api/iletisimCount", requestOptionsyukle).then(res => res.json()).then(
  //     (res) => {

  //       console.log(res);
  //       setcount(res)

  //     },
  //     () => {

  //       alert("Hata oluştu.");

  //     }

  //   ).catch(error => alert(error));

  // }

  // useEffect(() => {

  //   axiosFunc();
  // }, [])

  return (
    <div>
      <div className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div className="container-fluid">
          
           
            <button onClick={() => (closeLayer === true ? setcloseLayer(false) : setcloseLayer(true))} className="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
              <span className="navbar-toggler-icon icon-bar" />
            </button>
          
          <div className="collapse navbar-collapse justify-content-end">

            <ul className="navbar-nav">
 
              <li className="nav-item dropdown">
                <Link className="nav-link" to="#" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <FontAwesomeIcon className="material-icons" icon={faUser}  > </FontAwesomeIcon>

                  <p className="d-lg-none d-md-block">
                    Account
                  </p>
                </Link>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                  {/* <Link className="dropdown-item" to="/kullGunc">Kullanıcı  Güncelle</Link> */}
                  <Link className="dropdown-item" to="/sifreGunc">Şifre Güncelle</Link>
                  <div className="dropdown-divider" />
                  <Link className="dropdown-item" to="/logout">Çıkış</Link>
                </div>
              </li>

            </ul>

          </div>
          
        </div>
      </div>
    </div >
  )
}

export default NavBar
