import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from 'reactstrap';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios';

import { useDispatch } from "react-redux";

import { userLogin } from "../../../../redux/redux";
const SifeGunc = () => {




    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };


    const dispatch = useDispatch();

    const [eskiSifre, seteskiSifre] = useState('');
    const [yeniSifre, setyeniSifre] = useState('');
    const [yeniSifreT, setyeniSifreT] = useState('');

    const [pageLoading, setpageLoading] = useState(true)

    const [isLoading, setIsLoading] = useState(false)




    const [alertTrue, setAlertTrue] = useState(false)
    const [alertFalse, setAlertFalse] = useState(false)



    const submit = (e) => {

        e.preventDefault();

        setAlertFalse(false)
        setAlertTrue(false)


        setIsLoading(true);




        let deger = true;
        let gidecekMesaj = "";

        if (eskiSifre.trim().length <= 2) {

            gidecekMesaj += "Mevcut Şifrenizi Girin.";
            deger = false;

        }

        if (yeniSifre.trim().length <= 2) {

            gidecekMesaj += "Yeni Şifre Girin.";
            deger = false;

        } else
            if (yeniSifre != yeniSifreT) {
                gidecekMesaj += "Yeni Şifre Tekrarı Yanlış.";
                deger = false;
            }



        if (deger) {

            const frdata = new FormData();
            frdata.append('eskiSifre', eskiSifre);
            frdata.append('yeniSifre', yeniSifre);




            console.clear();
            console.log(headRequest);
            axios.post("/webapi/sifreGunc", frdata, headRequest).then(
                (response) => {


                    setIsLoading(true);

                    let klinikid = response.data.klinikId;
                    let yonSifre = response.data.sifre;

                    localStorage.clear();

                    localStorage.setItem('ykyon', JSON.stringify(response.data));

                    (dispatch(userLogin({
                        klinikid,
                        yonSifre
                    })).then(() => {
                        alert("Güncellendi.")
                    }));



                    seteskiSifre('');
                    setyeniSifre('');
                    setyeniSifreT('');

                    setIsLoading(false);
                    setAlertTrue(true);

                }

            ).catch(({error}) => {
                alert("Geçersiz şifre");
                seteskiSifre('');
                setyeniSifre('');
                setyeniSifreT(''); 
                setIsLoading(false); 
            });


            // history.push("/adminimt")

            // .then(a => a.json())
            // .then(a => setdonenJson(a), userKontroller
            // )

        } else {
            alert(gidecekMesaj);
            setIsLoading(false);
        }






    }













    return (

        <div>
            <div className="card-header card-header-primary">
                <h4 className="card-title">Şifre Güncelle</h4>
            </div>

            <div className="card-body">
                <div className="row">
                    {pageLoading &&
                        <form className="form-horizontal" id="form" name="form" action="#">


                            <div className="col-md-12">

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Eski Şifre:</label>
                                    <div className="col-lg-9">
                                        <input type="password" id="eskiSifre" name="eskiSifre" className="form-control" onChange={e => seteskiSifre(e.target.value)} value={eskiSifre} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Yeni şifre:</label>
                                    <div className="col-lg-9">
                                        <input type="password" id="yeniSifre" name="yeniSifre" className="form-control" onChange={e => setyeniSifre(e.target.value)} value={yeniSifre} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Yeni Şifre Tekrar:</label>
                                    <div className="col-lg-9">
                                        <input type="password" id="SifreTekrar" name="SifreTekrar" className="form-control" onChange={e => setyeniSifreT(e.target.value)} value={yeniSifreT} />
                                    </div>
                                </div>

                            </div>



                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-right">


                                        {alertTrue && <Alert color="success">
                                            <h6>Bilgileriniz güncellendi.</h6> </Alert>}

                                        {alertFalse && <Alert color="danger"  >
                                            <h6>İşlem Yapılamadı Bilgilerinizi kontrol edin!</h6> </Alert>}




                                        {isLoading && <Button color="primary" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz </Button>}

                                        {!isLoading && <Button color="success" onClick={submit}   >Güncelle  </Button>}

                                    </div>
                                </div>
                            </div>




                        </form>
                    }



                    {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}
                </div>

            </div>
        </div>

    )




};

export default SifeGunc;
