import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt, faTimes, } from '@fortawesome/free-solid-svg-icons'

import { getFormatDatetime } from '../../../func/funcColl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import Calendar from 'react-calendar';

const HastaDetay = () => {
    const { id } = useParams();
    let history = useHistory();



    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };



    const [pageLoading, setpageLoading] = useState(false)

    const [showhastadrm, setshowhastadrm] = useState(false)
    const [hasta, sethasta] = useState({});

    const [durum, setdurum] = useState(true);
    const [aciklama, setaciklama] = useState('');
    const [isleniyor, setisleniyor] = useState(false)
    const [showrandevuEkle, setshowrandevuEkle] = useState(false)

    const [randevu, setrandevu] = useState(new Date());
    const [secilenrandevu, setsecilenrandevu] = useState(new Date());

    const [randevuLoading, setrandevuLoading] = useState(false)
    const [gunlukRandevu, setgunlukRandevu] = useState([])

    const [randevuDoktorsecim, setrandevuDoktorsecim] = useState(false)

    const [yukleme, setyukleme] = useState(0);

    const [doktorlar, setdoktorlar] = useState([]);

    const [secilenSaat, setsecilenSaat] = useState('00:00');

    const [aciklamaText, setaciklamaText] = useState('');

    const [showRandevuiptal, setshowRandevuiptal] = useState(false)

    const [iptalSecRandevu, setiptalSecRandevu] = useState({});

    const [sevkekle, setsevkekle] = useState(false);

    const [sevkDoktor, setsevkDoktor] = useState(0);

    const [sevkAciklama, setsevkAciklama] = useState('')


    useEffect(() => {

        setrandevuLoading(true)
        console.clear();
        console.log(randevu)


        let datee;
        if (yukleme !== 0) {
            datee = new Date(randevu);
            if (datee > new Date()) {
                datee.setDate(datee.getDate() + 1)
            }
            setsecilenrandevu(datee.toISOString().split('T')[0])
        }
        else {

            datee = new Date(randevu);
            setsecilenrandevu(datee.toISOString().split('T')[0])
            setyukleme(1);
        }


        let tarihi = datee.toISOString().split('T')[0] + "";
        console.log('datee ' + tarihi)

        axios.get("webapi/GunlukRandevuDetay?tarih=" + tarihi, headRequest).then((res) => {

            setgunlukRandevu(res.data);
            console.log(res.data);
            setrandevuLoading(true)
        }

        ).catch((error) => {
            alert(console.error());
            setrandevuLoading(true);
        })


    }, [randevu])





    const [secilenDoktor, setsecilenDoktor] = useState(0)

    const handleClose = () => {
        setshowhastadrm(false)
        setshowrandevuEkle(false)
        setaciklamaText('')
        setshowRandevuiptal(false)
        setsecilenrand(0)
        setsevkekle(false)
    }

    useEffect(() => {



        axios.get("/webapi/HekimList", headRequest).then(
            (res) => {
                console.log(res.data)
                setdoktorlar(res.data)

            }
        ).catch(error => alert(error))



        axios.get("/webapi/HastaDetay-" + id, headRequest).then(
            (res) => {

                sethasta(res.data)

                setdurum(res.data.detay.durum);
                setaciklama(res.data.detay.aciklama)

                setpageLoading(true);

            }
        ).catch(error => alert(error))



    }, [])


    const randevuEkraniAc = (saat) => {

        setsecilenSaat(saat); setrandevuDoktorsecim(true);
    }
    const hastaDrmSubmit = (e) => {

        setisleniyor(true);
        const frdata = new FormData();
        frdata.append('durum', durum);
        frdata.append('aciklama', aciklama);

        axios.post("/webapi/HastaDetayDurum-" + id, frdata, headRequest).then(
            (response) => {

                setpageLoading(false);
                if (response.status === 200) {

                    sethasta(response.data)

                    setdurum(hasta.detay.durum);
                    setaciklama(hasta.detay.aciklama)

                }
                else {
                    alert(response.statusText);

                }
                setisleniyor(false);
                setpageLoading(true);
            }
        ).catch(error => alert(error), setisleniyor(false), setpageLoading(true))

    }



    const randevuİptal = (a) => {

        setiptalSecRandevu(a)
        setshowRandevuiptal(true)

    }


    const hastaRandevuİptal = (a) => {

        console.log("/webapi/HastaRandevuiptal-" + iptalSecRandevu.rndvId);
        axios.post("/webapi/HastaRandevuiptal-" + iptalSecRandevu.rndvId, "", headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {

                    alert("Güncellendi.");
                    sethasta(response.data)

                    setdurum(response.data.detay.durum);
                    setaciklama(response.data.detay.aciklama)

                    handleClose()
                }
                else {
                    alert(response.statusText);

                }

                setisleniyor(false)
            }
        ).catch(error => alert(error),
            setisleniyor(false));

    }


    const [secilenRand, setsecilenrand] = useState(0)


    const RandevuSevk = (e) => {



        setisleniyor(true)
        const frdata = new FormData();

        frdata.append('aciklama', sevkAciklama);
        frdata.append('hekim', sevkDoktor);

        axios.post("/webapi/HastaRandevuSevkOlustur-" + secilenRand, frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {


                    history.push("hasta-sevkdetay-" + response.data);
                }
                else {
                    alert(response.statusText);
                    handleClose()

                }

                setisleniyor(false)
            }
        ).catch(error => alert(error),
            setisleniyor(false));


    }





    const yeniRandevu = (e) => {


        setisleniyor(true)
        const frdata = new FormData();

        frdata.append('tarih', secilenrandevu);
        frdata.append('saat', secilenSaat);
        frdata.append('aciklama', aciklamaText);
        frdata.append('hekim', secilenDoktor);

        console.log(frdata);

        axios.post("/webapi/HastaRandevuOlustur-" + id, frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {

                    alert("Güncellendi.");
                    sethasta(response.data)

                    setdurum(response.data.detay.durum);
                    setaciklama(response.data.detay.aciklama)

                    handleClose()
                }
                else {
                    alert(response.statusText);

                }

                setisleniyor(false)
            }
        ).catch(error => alert(error),
            setisleniyor(false));








    }



    return (

        <div>

            <div className="card-header card-header-primary">
                <h4 className="card-title">Detay</h4>
            </div>

            <div className="card-body">

                {pageLoading && <form className="form-horizontal" id="form" name="form" action="#">

                    <Link to="hastalar" style={{ margin: "20px" }} >Geri Dön</Link>

                    <div className='text-center'><Link to={'hasta-detaygnc-' + id} className='btn btn-info'>Güncelle</Link></div>


                    <div className="row">

                        <div className="col-lg-2 col-md-2 col-sm-12 float-left">


                            <div className="form-group">
                                <div className="col-lg-12 text-center">

                                    <img src='/hasta.png' />

                                    <br />

                                    {hasta.detay.durum === true &&
                                        <button type='button' className='btn btn-success' onClick={() => setshowhastadrm(true)} >Aktif Hasta</button>}

                                    {hasta.detay.durum !== true &&
                                        <>

                                            <button type='button' className='btn btn-info' onClick={() => setshowhastadrm(true)}>Kapalı</button>

                                        </>
                                    }

                                </div>
                                <div><strong>{hasta.detay.aciklama}</strong>  </div>



                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 float-left">
                            <div className="col-md-12">
                                <div>

                                    <h2>  {hasta.detay.ad.toUpperCase()} {hasta.detay.soyad.toUpperCase()} </h2>
                                    <div><strong>Tc</strong> : {hasta.detay.tc} </div>

                                    <div><strong>Doğum Tarihi</strong> : {getFormatDatetime(hasta.detay.dogumT)} </div>
                                    <div><strong>Anne Adı</strong> : {hasta.detay.anneAdi.toUpperCase()} </div>
                                    <div><strong>Baba Adı</strong> : {hasta.detay.babaAdi.toUpperCase()} </div>


                                    <div><strong>Üyelik Tarihi</strong> : {getFormatDatetime(hasta.detay.tarih)} </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 float-left">
                            <div className="col-md-12">
                                <div>

                                    <div><strong>Telefon</strong> : {hasta.detay.telefon} </div>
                                    <div><strong>Mail</strong> : {hasta.detay.mail} </div>
                                    <div><strong>Referans</strong> : {hasta.detay.referans} </div>
                                    <div><strong>Adres</strong> : {hasta.detay.adres} </div>








                                </div>
                            </div>
                        </div>


                    </div>

                    <div className='clearfix'></div>

                    <div className='row' style={{ marginTop: '75px' }}>
                        <div className='text-center'>
                            <Link to={'hasta-sevk-' + id} className="btn btn-warning" >Hasta Sevk Detay</Link>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-12 table-responsive " style={{ marginTop: "50px" }}>


                            <h5>Randevu Listesi </h5>


                            <table className="table table-striped  ">
                                <thead className="">
                                    <tr>

                                        <th>
                                            Hekim Ad
                                        </th>
                                        <th>
                                            Tarih
                                        </th>
                                        <th>
                                            Açıklama
                                        </th>

                                        <th>
                                            Durum
                                        </th>
                                        <th>

                                        </th>
                                    </tr>

                                    <tr>
                                        <th colSpan='5' className='text-center'>
                                            <button type='button' onClick={() => setshowrandevuEkle(true)} className="btn btn-success" >Randevu Gir</button>
                                        </th>
                                    </tr>




                                </thead>

                                <tbody>{hasta.randevu.length > 0 &&

                                    hasta.randevu.map(a =>
                                        <tr key={a.rndvId}>
                                            <td>
                                                {a.hekimAd}
                                            </td>
                                            <td>
                                                {getFormatDatetime(a.tarih)}  {a.saat}
                                            </td>

                                            <td>
                                                {a.aciklama}
                                            </td>
                                            <td className='text-center'>
                                                {a.durum &&
                                                    <>
                                                        {a.sevkId === null && <> <div className='text-center'>  İşlem Yapılmadı</div>
                                                            <button type='button' onClick={(e) => randevuİptal(a)} className='btn btn-danger btn-sm'>İptal</button></>}

                                                        {!a.durum === null && <Link onClick={() => alert("Yapım Aşamasında.")}>İşlem detay</Link>}
                                                    </>
                                                }
                                                {!a.durum && <span style={{ color: "red" }}> <FontAwesomeIcon icon={faTimes} style={{ color: 'red' }} /> İPTAL</span>}
                                            </td>
                                            <td>
                                                {(a.sevkId === null && a.durum === true) ? <button type='button' onClick={() => { setsecilenrand(a.rndvId); setsevkekle(true) }} className='btn btn-success'>Sevk Gir</button> : <span>-</span>}

                                            </td>
                                        </tr>)
                                }
                                    {hasta.randevu.length === 0 &&
                                        <tr>
                                            <td colSpan='4' className='text-center'>
                                                <h3>Mevcut Randevu bulunmuyor...</h3>
                                            </td>
                                        </tr>

                                    }

                                </tbody>
                            </table>

                        </div>
                    </div>



                </form>}

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}


            </div>




            <Modal isOpen={showrandevuEkle} size='lg'   >
                <ModalHeader toggle={handleClose}>{secilenrandevu} Tarihli Randevu Liste</ModalHeader>
                <ModalBody>

                    <div className="row">

                        <div className="col-md-4">

                            <div className="form-group text-center">

                                <Calendar minDate={new Date()}
                                    onChange={setrandevu}
                                    value={randevu}
                                />

                            </div>
                            {randevuDoktorsecim && <div className='response'>

                                <h5>Seçilen Saat {secilenSaat}</h5>
                                Hekim Seç
                                <table className='table'>
                                    <tbody>{doktorlar.map(a =>
                                        <tr>
                                            <td>{a.hekim.hekimAd}</td>
                                            <td>
                                                <button className={'btn ' + (secilenDoktor == a.hekim.hkId && 'btn-success')} onClick={() => setsecilenDoktor(a.hekim.hkId)}>Seç</button>
                                            </td>
                                        </tr>)}
                                    </tbody>

                                </table>

                                {(secilenDoktor > 0 && !isleniyor) && <div className='text-right'>
                                    <>
                                        <div className='form-group'>
                                            <textarea className='form-control' onChange={(e) => setaciklamaText(e.target.value)} value={aciklamaText} ></textarea>
                                        </div>
                                        <Button color="success" onClick={yeniRandevu}>Randevuyu Onayla </Button>
                                    </>
                                </div>}


                                {isleniyor && <div>
                                    İşleniyor...
                                </div>}




                            </div>}
                            <div>


                            </div>

                        </div>
                        <div className="col-md-8">


                            <div className='response'>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <td></td>
                                            <td>Hekim - Hasta</td>
                                            <td></td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {randevuLoading && (gunlukRandevu.length > 0 ? (gunlukRandevu.map(a =>
                                            <tr key={a.saat}>

                                                <td>{a.saat}</td>
                                                <td>
                                                    {a.randevuListesi.length > 0 && a.randevuListesi.map(b =>
                                                        <div>  Hk :{b.hekimad.toUpperCase()} - <a href={'hasta-detay-' + b.hastaid}><strong>{b.hastaad.toUpperCase()}</strong></a></div>
                                                    )}
                                                    <div>

                                                        <button onClick={() => randevuEkraniAc(a.saat)} className={'btn ' + (secilenSaat == a.saat && 'btn-success')}>Randevu oluştur</button>
                                                    </div>
                                                </td>
                                            </tr>)) :
                                            (<tr>
                                                <td colSpan={3}>-</td>
                                            </tr>))
                                        }
                                        {!randevuLoading &&
                                            <tr>
                                                <th colSpan={3}>Yükleniyor...</th>
                                            </tr>
                                        }
                                    </tbody>
                                </table>


                            </div>
                        </div>

                    </div>

                </ModalBody>
                {!isleniyor && <ModalFooter>

                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



            <Modal isOpen={showhastadrm}>
                <ModalHeader toggle={handleClose}>Hasta Detay</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="col-lg-12 control-label">Kategori adı:</label>
                            <div className="col-lg-12">

                                <select className="form-control" onChange={e => setdurum(e.target.value)} name="AnaSayfa" id="AnaSayfa">
                                    <option value={1} selected={durum == 1}>Aktif</option>
                                    <option value={0} selected={durum == 0}>Pasif</option>
                                </select>


                            </div>
                        </div>

                        <div className="form-group">
                            <label className="col-lg-12 control-label">Açıklama:</label>
                            <div className="col-lg-12">

                                <textarea className="form-control" onChange={e => setaciklama(e.target.value)} value={aciklama} ></textarea>

                            </div>
                        </div>
                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="success" onClick={hastaDrmSubmit}>Değiştir</Button>
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



            <Modal isOpen={showRandevuiptal}>
                <ModalHeader toggle={handleClose}>Randevu İptal</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="col-lg-12 control-label">{iptalSecRandevu.saat} randevusunu iptal etmek istediginize emin misiniz?</label>

                        </div>

                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="success" onClick={hastaRandevuİptal}>Değiştir</Button>
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>






            <Modal isOpen={sevkekle}   >
                <ModalHeader toggle={handleClose}>Randevu Sevk Oluştur</ModalHeader>
                <ModalBody>

                    <div className="row">

                        <div className="col-md-12">
                            {doktorlar &&
                                <div className='response'>

                                    <table className='table'>
                                        <tbody>{doktorlar.map(a =>
                                            <tr>
                                                <td>Hk. {a.hekim.hekimAd}</td>
                                                <td>
                                                    <button className={'btn ' + (sevkDoktor == a.hekim.hkId && 'btn-success')} onClick={() => setsevkDoktor(a.hekim.hkId)}>Seç</button>
                                                </td>
                                            </tr>)}
                                        </tbody>

                                    </table>
                                    {isleniyor && <div>
                                        İşleniyor...
                                    </div>}

                                </div>
                            }


                            <div className="form-group">
                                <label className="col-lg-12 control-label">Açıklama:</label>
                                <div className="col-lg-12">

                                    <textarea className="form-control" onChange={e => setsevkAciklama(e.target.value)} value={sevkAciklama} ></textarea>

                                </div>
                            </div>



                            <div>


                            </div>

                        </div>

                    </div>

                </ModalBody>
                {!isleniyor && <ModalFooter>
                    {sevkDoktor !== 0 && <Button color="success" onClick={RandevuSevk}>Oluştur</Button>}
                    {sevkDoktor === 0 && <span>Doktor Seçin</span>}
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>


        </div>

    )





}

export default HastaDetay
