import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

// import { getFormatDatetime, getFormattedPrice, headRequest } from '../../../func/funcColl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import Calendar from 'react-calendar';


const Randevu = () => {


  const girisler = (JSON.parse(localStorage.getItem("ykyon")));

  const headRequest = {
    headers: {
      'Content-Type': 'application/json'
      , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
    }
  };

  let history = useHistory();

  const [pageLoading, setpageLoading] = useState(true)
  const [HastaAra, setHastaAra] = useState(false)

  const [hastatc, sethastatc] = useState('')


  const [isleniyor, setisleniyor] = useState(false)
  const [showrandevuEkle, setshowrandevuEkle] = useState(false)

  const [randevu, setrandevu] = useState(new Date());
  const [secilenrandevu, setsecilenrandevu] = useState('');

  const [randevuLoading, setrandevuLoading] = useState(false)
  const [gunlukRandevu, setgunlukRandevu] = useState([])

  const [hastaListesi, sethastalistesi] = useState([])

  const [randevuDoktorsecim, setrandevuDoktorsecim] = useState(false)

  const [yukleme, setyukleme] = useState(0);

  const [doktorlar, setdoktorlar] = useState(true);

  const [secilenSaat, setsecilenSaat] = useState('00:00');

  const [aciklamaText, setaciklamaText] = useState('');

  const [showRandevuiptal, setshowRandevuiptal] = useState(false)


  const [iptalSecRandevu, setiptalSecRandevu] = useState({});



  const [secilenHasta, setsecilenHasta] = useState();

  useEffect(() => {

    setrandevuLoading(true)
    console.clear();
    console.log(randevu)
 
    let datee;
 
    datee = new Date(randevu);
    if (datee !== new Date()) {
      datee.setDate(datee.getDate() + 1)
    }
    setsecilenrandevu(datee.toISOString().split('T')[0])
 
    let tarihi = datee.toISOString().split('T')[0] + "";
    console.log('datee ' + tarihi)

    axios.get("webapi/GunlukRandevuDetay?tarih=" + tarihi, headRequest).then((res) => {

      setgunlukRandevu(res.data);
      console.log(res.data);
      setrandevuLoading(true)
    }

    ).catch((error) => {
      alert(console.error());
      setrandevuLoading(true);
    })


  }, [randevu])




  useEffect(() => {

    if (hastatc.length > 2) {

      const frdata = new FormData();

      frdata.append('tc', hastatc);


      axios.post("/webapi/hastaAra", frdata, headRequest).then(
        (response) => {
          console.log(response)
          if (response.status === 200) {


            sethastalistesi(response.data)


          }
          else {
            alert(response.statusText);

          }

        }
      ).catch(error => alert(error));

    } else {

      sethastalistesi([])

    }



  }, [hastatc])







  const [secilenDoktor, setsecilenDoktor] = useState(0)

  const handleClose = () => {
    setHastaAra(false)
    setshowrandevuEkle(false)
    setaciklamaText('')
    setshowRandevuiptal(false)
    sethastatc('')
  }


  const hastasecc = (a) => {
    setsecilenHasta(a);
    console.log(a);
    handleClose()

  }



  useEffect(() => {



    axios.get("/webapi/HekimList", headRequest).then(
      (res) => {
        console.log(res.data)
        setdoktorlar(res.data)

      }
    ).catch(error => alert(error))



  }, [])


  const randevuEkraniAc = (saat) => {

    setsecilenSaat(saat); setrandevuDoktorsecim(true);
  }



  const randevuİptal = (a) => {

    setiptalSecRandevu(a)
    setshowRandevuiptal(true)

  }


  const hastaRandevuİptal = (a) => {

    console.log("/webapi/HastaRandevuiptal-" + iptalSecRandevu.rndvId);

    axios.post("/webapi/HastaRandevuiptal-" + iptalSecRandevu.rndvId, "", headRequest).then(
      (response) => {
        console.log(response)
        if (response.status === 200) {

          alert("Güncellendi.");



          handleClose()
        }
        else {
          alert(response.statusText);

        }

        setisleniyor(false)
      }
    ).catch(error => alert(error),
      setisleniyor(false));

  }




  const yeniRandevu = (e) => {

    setrandevuLoading(false);
    setisleniyor(true)
    const frdata = new FormData();

    frdata.append('tarih', secilenrandevu);
    frdata.append('saat', secilenSaat);
    frdata.append('aciklama', aciklamaText);
    frdata.append('hekim', secilenDoktor);

    console.log(frdata);

    axios.post("/webapi/HastaGenelRandevuOlustur-" + secilenHasta.hastaId, frdata, headRequest).then(
      (response) => {
        console.log(response)
        if (response.status === 200) {

          alert("Oluşturuldu.");

          setgunlukRandevu(response.data);
          console.log(response.data);
          setrandevuLoading(true)


          setsecilenSaat('00:00')
          setsecilenHasta()
          setrandevuDoktorsecim(false)
          handleClose()
          setsecilenDoktor(0)
        }
        else {
          alert(response.statusText);

        }

        setrandevuLoading(true);
        setisleniyor(false)
      }
    ).catch(error => alert(error),
      setrandevuLoading(true),
      setisleniyor(false));








  }



  return (

    <div>

      <div className="card-header card-header-primary">
        <h4 className="card-title">Detay</h4>
      </div>

      <div className="card-body">

        {pageLoading && <>



          <div className="row">

            <div className="col-md-4 float-left">


              <div className="form-group text-center">

                <Calendar
                  onChange={setrandevu}
                  value={randevu}
                />

              </div>

              {randevuDoktorsecim && <div className='response'>

                <h5>Seçilen Saat {secilenSaat}</h5>
                Hekim Seç
                <table className='table'>
                  <tbody>{doktorlar.map(a =>
                    <tr>
                      <td>{a.hekim.hekimAd}</td>
                      <td>
                        <button className={'btn ' + (secilenDoktor == a.hekim.hkId && 'btn-success')} onClick={() => setsecilenDoktor(a.hekim.hkId)}>Seç</button>
                      </td>
                    </tr>)}
                  </tbody>

                </table>

                {(secilenDoktor > 0 && secilenHasta && !isleniyor) && <div className='text-right'>
                  <>
                    <div className='form-group'>
                      <textarea className='form-control' onChange={(e) => setaciklamaText(e.target.value)} value={aciklamaText} ></textarea>
                    </div>


                    <Button color="success" onClick={yeniRandevu}>Randevuyu Onayla </Button>
                  </>
                </div>}


                {isleniyor && <div>
                  İşleniyor...
                </div>}




              </div>}
              <div>


              </div>

            </div>
            <div className="col-md-8 float-left">

              <div>
                {secilenHasta ?
                  <div>

                    <Link to={'hasta-detay-' + secilenHasta.hastaId}>
                      <div className="col-lg-4 float-left">
                        <img src='/hasta.png' />
                      </div>
                      <div className="col-lg-8 float-left">

                        <h3> {secilenHasta.hastaad}</h3>

                      </div></Link>

                    <div style={{ marginTop: "50px", marginBottom: "50px" }} className='text-center'>
                      <button type='button' onClick={() => setHastaAra(true)} className='btn btn-info'>Hasta Değiştir</button></div>


                  </div> : <div style={{ marginTop: "50px", marginBottom: "50px" }} className='text-center'>
                    <button type='button' onClick={() => setHastaAra(true)} className='btn btn-success'>Hasta Seç</button> </div>
                }
              </div>




              <h2>{secilenrandevu} Tarihli Randevu Liste</h2>

              <div className='response'>
                <table className='table'>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Hekim - Hasta</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {randevuLoading && (gunlukRandevu.length > 0 ? (gunlukRandevu.map(a =>
                      <tr key={a.saat}>

                        <td>{a.saat}</td>
                        <td>
                          {a.randevuListesi.length > 0 && a.randevuListesi.map(b =>
                            <div>  Hk :{b.hekimad.toUpperCase()} - <Link to={'hasta-detay-' + b.hastaid}> <strong>{b.hastaad.toUpperCase()}</strong></Link></div>
                          )}
                          <div>

                          </div>
                        </td>
                        <td>
                          <button onClick={() => randevuEkraniAc(a.saat)} className={'btn ' + (secilenSaat == a.saat && 'btn-success')}>Randevu oluştur</button>
                        </td>
                      </tr>)) :
                      (<tr>
                        <td colSpan={3}>-</td>
                      </tr>))
                    }
                    {!randevuLoading &&
                      <tr>
                        <th colSpan={3}>Yükleniyor...</th>
                      </tr>
                    }
                  </tbody>
                </table>


              </div>
            </div>

          </div>




          {/* <div className="col-md-12 table-responsive " style={{ marginTop: "50px" }}>
 
            <>
              {a.sevkId === null && <> <span >İşlem Yapılmadı</span>
                <button type='button' onClick={(e) => randevuİptal(a)} className='btn btn-danger btn-sm'>İptal</button></>}

              {!a.durum === null && <Link onClick={() => alert("Yapım Aşamasında.")}>İşlem detay</Link>}
            </>
          </div> */}




        </>


        }

        {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}


      </div>


      <Modal isOpen={showRandevuiptal}>
        <ModalHeader toggle={handleClose}>Randevu İptal</ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-lg-12 control-label">{iptalSecRandevu.saat} randevusunu iptal etmek istediginize emin misiniz?</label>

            </div>

          </div>
        </ModalBody>
        {!isleniyor && <ModalFooter>
          <Button color="success" onClick={hastaRandevuİptal}>Değiştir</Button>
          <Button color="secondary" onClick={handleClose}>İptal</Button>
        </ModalFooter>}

        {isleniyor && <ModalFooter>
          İşleniyor...
        </ModalFooter>}
      </Modal>











      <Modal isOpen={HastaAra} size='lg'>
        <ModalHeader toggle={handleClose}>Hasta Ara</ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            <div className='response'>

              <div className='form-group'>
                <input className='form-control' name='tc' onChange={(e) => sethastatc(e.target.value)} value={hastatc} placeholder='Tc veya Soyad' />
              </div>
              <table className='table'>
                <thead>
                  <tr>
                    <td>Tc</td>
                    <td> Ad Soyad</td>
                    <td>Doğum Tarihi</td>
                  </tr>
                </thead>

                <tbody>
                  {hastatc.length > 2 && <>
                    {(hastaListesi && hastaListesi.length > 0) ? (hastaListesi.map(a =>
                      <tr key={a.hastaId}>

                        <td>{a.tc}</td>
                        <td>
                          {a.hastaad.toUpperCase()}
                        </td>
                        <td>
                          {a.dogumT}
                        </td>
                        <td>
                          <button className='btn btn-success' onClick={() => { hastasecc(a) }}>Seç</button>
                        </td>
                      </tr>)) : (hastaListesi.length === 0 &&
                        <tr>
                          <th colSpan={3}>Hasta bulunamadı...</th>
                        </tr>)

                    }</>}

                  {hastatc.length < 2 &&
                    <tr>
                      <th colSpan={3}>Hasta Ara...</th>
                    </tr>
                  }
                </tbody>
              </table>

            </div>

          </div>
        </ModalBody>
        {!isleniyor && <ModalFooter>
          <Link className='btn btn-info' to={'hasta-ekle'} >Yeni Hasta Ekle</Link>
          <Button color="secondary" onClick={handleClose}>İptal</Button>
        </ModalFooter>}

        {isleniyor && <ModalFooter>
          İşleniyor...
        </ModalFooter>}
      </Modal>

      {/*  */}










    </div >

  )






}

export default Randevu
