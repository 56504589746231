import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt, faTimes, faCheck, faStar, faHistory } from '@fortawesome/free-solid-svg-icons'

import { getFormatDatetime, getFormattedPrice, } from '../../../func/funcColl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';

const Hastasevk = () => {

    const { id } = useParams();

    let history = useHistory();


    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };
    const [pageLoading, setpageLoading] = useState(false)

    const [hasta, sethasta] = useState({});
    const [doktorlar, setdoktorlar] = useState([]);
    const [sevkekle, setsevkekle] = useState(false);

    const [isleniyor, setisleniyor] = useState(false)

    const [aciklamaText, setaciklamaText] = useState('');


    const [secilenDoktor, setsecilenDoktor] = useState(0)

    const handleClose = () => {

        setsecilenDoktor(0)
        setsevkekle(false)
        setaciklamaText('')
    }

    useEffect(() => {



        axios.get("/webapi/HekimList", headRequest).then(
            (res) => {
                console.log(res.data)
                setdoktorlar(res.data)

            }
        ).catch(error => alert(error))



        axios.get("/webapi/HastaSevkler-" + id, headRequest).then(
            (res) => {
                console.clear();
                console.log(res.data);
                sethasta(res.data)

                setpageLoading(true);

            }
        ).catch(error => alert(error))



    }, [])




    const yeniSevk = (e) => {


        setisleniyor(true)
        const frdata = new FormData();

        frdata.append('aciklama', aciklamaText);
        frdata.append('hekim', secilenDoktor);

        axios.post("/webapi/HastasevkOlustur-" + id, frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {


                    history.push("hasta-sevkdetay-" + response.data);
                }
                else {
                    alert(response.statusText);
                    handleClose()

                }

                setisleniyor(false)
            }
        ).catch(error => alert(error),
            setisleniyor(false));



    }



    return (

        <div>

            <div className="card-header card-header-primary">
                <h4 className="card-title">Detay</h4>
            </div>

            <div className="card-body">

                {pageLoading && <form className="form-horizontal" id="form" name="form" action="#">

                    <Link to={"hasta-detay-" + id} style={{ margin: "20px" }} >Geri Dön</Link>


                    <div className="row">

                        <div className="col-lg-2 col-md-2 col-sm-12 float-left">


                            <div className="form-group">
                                <div className="col-lg-12 text-center">

                                    <img src='/hasta.png' />

                                    <br />

                                </div>
                                <div><strong>{hasta.detay.aciklama}</strong>  </div>



                            </div>
                        </div>

                        <div className="col-lg-10 col-md-10 col-sm-12 float-left">
                            <div className="col-md-12">
                                <div>

                                    <div><strong>Ad Soyad</strong> : {hasta.detay.ad.toUpperCase()} {hasta.detay.soyad.toUpperCase()} </div>
                                    <div><strong>Tc</strong> : {hasta.detay.tc} </div>

                                    <div><strong>Doğum Tarihi</strong> : {getFormatDatetime(hasta.detay.dogumT)} </div>
                                    <div><strong>Telefon</strong> : {hasta.detay.telefon} </div>


                                </div>
                            </div>
                        </div>



                    </div>

                    <div className='clearfix'></div>



                    <div className='row'>
                        <div className="col-md-12 table-responsive " style={{ marginTop: "50px" }}>


                            <h5 className='text-center'>  {hasta.detay.ad.toUpperCase()} {hasta.detay.soyad.toUpperCase()}  Sevk Listesi </h5>


                            <table className="table table-striped  ">
                                <thead className="">
                                    <tr>

                                        <th>
                                            Tarih
                                        </th>
                                        <th>
                                            Hekim Ad
                                        </th>
                                        <th>
                                            Açıklama
                                        </th>

                                        <th>
                                            İşlem
                                        </th>
                                        <th>
                                            Ödeme
                                        </th>
                                        <th>

                                        </th>
                                    </tr>

                                    <tr>
                                        <th colSpan='5' className='text-center'>
                                            <button type='button' className="btn btn-success" onClick={() => setsevkekle(true)} >Sevk ekle</button>
                                        </th>
                                    </tr>




                                </thead>

                                <tbody>{(hasta && hasta.sevkler.length > 0) && hasta.sevkler.map(a =>
                                    <tr key={a.sevkId}>
                                        <td>
                                            {getFormatDatetime(a.tarih)}
                                        </td>
                                        <td>
                                            {a.hekimAd}
                                        </td>

                                        <td>
                                            {a.aciklama}
                                        </td>
                                        <td>
                                            {a.tetkikler.length > 0 ? a.tetkikler.map(t =>
                                                <>
                                                    <div> <strong>{t.ismi}</strong>   Diş: {t.dis}  </div>


                                                </>
                                            ) : <span>Tetkik Yapılmadı</span>}


                                        </td>
                                        <td>
                                            {a.kalanUcret > 0 ? <> Kalan Ücret {getFormattedPrice(a.kalanUcret)} TL</>
                                                : <FontAwesomeIcon icon={faCheck} />}
                                        </td>
                                        <td>
                                            <Link to={'hasta-sevkdetay-' + a.sevkId}>Detay</Link>

                                        </td>
                                    </tr>)
                                }


                                </tbody>
                            </table>

                        </div>
                    </div>



                </form>}

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}


            </div>



            <Modal isOpen={sevkekle}   >
                <ModalHeader toggle={handleClose}>Sevk Oluştur</ModalHeader>
                <ModalBody>

                    <div className="row">

                        <div className="col-md-12">

                            <div className='response'>

                                <h2>Hasta Adı : {pageLoading && <> {hasta.detay.ad.toUpperCase()}</>} </h2>

                                <table className='table'>
                                    <tbody>{doktorlar.map(a =>
                                        <tr>
                                            <td>Hk. {a.hekim.hekimAd}</td>
                                            <td>
                                                <button className={'btn ' + (secilenDoktor == a.hekim.hkId && 'btn-success')} onClick={() => setsecilenDoktor(a.hekim.hkId)}>Seç</button>
                                            </td>
                                        </tr>)}
                                    </tbody>

                                </table>

                                <div className='text-right'>

                                    <div className='form-group'>
                                        <textarea className='form-control' onChange={(e) => setaciklamaText(e.target.value)} value={aciklamaText} placeholder='Detay' ></textarea>
                                    </div>


                                </div>


                                {isleniyor && <div>
                                    İşleniyor...
                                </div>}




                            </div>
                            <div>


                            </div>

                        </div>

                    </div>

                </ModalBody>
                {!isleniyor && <ModalFooter>
                    {secilenDoktor !== 0 && <Button color="success" onClick={yeniSevk}>Oluştur</Button>}
                    {secilenDoktor === 0 && <span>Doktor Seçin</span>}
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>


        </div >

    )




}

export default Hastasevk
