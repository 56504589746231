import React, {useEffect} from 'react'
import {useDispatch} from "react-redux"; 
import { useHistory } from "react-router-dom"

import { userLogout } from "../../redux/redux";
 



export default () => {
  
  let history = useHistory();


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLogout())
  }, []);

  history.push("login");

  return (
    <div>Logging out...</div>
  )
};
