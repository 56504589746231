import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import { Button } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import axios from 'axios';


import './login.css'





const Forpassword = () => {



    const history = useHistory();


    const [isLoading, setIsLoading] = useState(false)



    const [email, setemail] = useState('');






    const submit = async (e) => {

        //     setIsLoading(true);

        //     if (e && e.preventDefault) { e.preventDefault(); }


        //     let deger = true;
        //     let gidecekMesaj = "";


        //     // const filesCount = file.length;





        //     if (email.trim().length <= 2) {
        //         alert("Email adresinizi girin");
        //         deger = false;
        //     }



        //     if (deger) {

        //         setIsLoading(true);

        //         const frdata = new FormData();

        //         frdata.append('email', email);


        //         axios.post("/adn_api/sifremiUnuttum", frdata, {
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             }
        //         }).then((response) => {
        //             console.log(response);
        //             if (response.status === 200  ) {

        //                 alert("Şifreniz mail adresinize gönderildi.");

        //             } else {
        //                 alert(response.statusText);
        //             }
        //             setIsLoading(false);
        //         })
        //             .catch((error) => {
        //                 console.log(error);
        //                 alert(error.response.data);

        //                 setIsLoading(false);
        //             });

        //     }



        return false
    }




    return (
        <div className='AdminLoginControl'>
            <div className='wrapper fadeInDown' >
                <div id='formContent'  >

                    <div className="fadeIn first">
                        <img
                            src='/images/logo.png'
                            id="icon"
                            alt="Logo"
                            style={{ width: 200 }}
                        />
                    </div>

                    <form onSubmit={submit}>


                        <h1>Şifremi Unuttum</h1>

                        <input type="text" id="email" className=' fadeIn  third ' name="login" placeholder="Email" onChange={e => setemail(e.target.value)} value={email} ></input>



                        {isLoading && <Button color="primary" size="lg" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz </Button>}

                        {!isLoading && <Button color="primary" size="lg"  >Gönder  </Button>}

                    </form>

                    <div id='formFooter'>
                        <Link className='underlineHover' to="/login">Giriş</Link>

                    </div>
                </div>
            </div>
        </div>

    )

}
export default Forpassword