import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

// import { getFormatDatetime, getFormattedPrice, headRequest } from '../../../func/funcColl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import Calendar from 'react-calendar';
import { getFormattedPrice } from '../../func/funcColl';


const Index = () => {

    const [randevu, setrandevu] = useState(new Date());
    const [secilenrandevu, setsecilenrandevu] = useState('');
    const [randevuLoading, setrandevuLoading] = useState(false)
    const [data, setdata] = useState({});
    const [pageLoading, setpageLoading] = useState(true)


    const [indexdata, setindexdata] = useState();

    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    useEffect(() => {

        axios.get("/webapi/KlinikIndexPage", headRequest).then(res => { setdata(res.data); setpageLoading(false); });

        axios.get("/webapi/indexData-" + (randevu.toISOString().split('T')[0]), headRequest).then(res => { setindexdata(res.data); console.log(res.data) });
        // .then(response => response.json())

    }, [])

    useEffect(() => {

        setrandevuLoading(true)
        console.clear();
        console.log(randevu)
        setindexdata()
        let datee;

        datee = new Date(randevu);
        if (datee !== new Date()) {
            datee.setDate(datee.getDate() + 1)
        }
        setsecilenrandevu(datee.toISOString().split('T')[0])

        let tarihi = datee.toISOString().split('T')[0] + "";
        console.log('datee ' + tarihi)

        axios.get("/webapi/indexData-" + (randevu.toISOString().split('T')[0]), headRequest).then(res => { setindexdata(res.data); console.log(res.data) });
        // axios.get("webapi/GunlukRandevuDetay?tarih=" + tarihi, headRequest).then((res) => {

        //   setgunlukRandevu(res.data);
        //   console.log(res.data);
        //   setrandevuLoading(true)
        // }

        // ).catch((error) => {
        //   alert(console.error());
        //   setrandevuLoading(true);
        // });

    }, [randevu])




    return (
        <div>
            {!pageLoading && <div className='indexWeb text-center'>




                <div className='row'>

                    <div className="col-md-6 float-left">

                        <div>
                            {data.Logo === null && <img src={'/logo.jpg'} alt="Logo" style={{ width: 200 }} />}
                            {data.Logo !== null && <img src={'images/' + data.Logo} alt="Logo" style={{ width: 200 }} />}
                        </div>
                        <div>
                            <h1>{data.KlinikAd}</h1>
                        </div>
                        <div>Yetkili : {data.Yetkili} - {data.YetkiliTel} </div>
                        <div>Tel : {data.Tel}</div>
                        <div>Email : {data.email}</div>
                        <div>Adres :<br /> {data.Adres}</div>

                    </div>
                    <div className="col-md-6 float-left">
                        <div className="form-group text-center">

                            <Calendar
                                onChange={setrandevu}
                                value={randevu}
                            />

                        </div>
                    </div>
                </div>
                <div className='clearfix'></div>
                <div className='row'>
                    {indexdata && <div className='col-md-12'>
                        <h2>{secilenrandevu} Tarihli Günlük</h2>

                        <div className='row text-center'>
                            <div class="adminCard  "><div class="cardNumber">{indexdata.hastaKartSayisi}</div>
                                <div class="cardaciklama">HASTA KARTI SAYISI  </div></div>

                            <div class="adminCard  "><div class="cardNumber">{indexdata.hastaSevkSayisi}</div>
                                <div class="cardaciklama">HASTA SEVK SAYISI</div></div>

                            <div class="adminCard  "><div class="cardNumber">{indexdata.girilenTetkikSayisi}</div>
                                <div class="cardaciklama">GİRİLEN TETKİK SAYISI</div></div>

                        </div>
                        <div className='row text-center'>

                            <div class="adminCard  "><div class="cardNumber">{getFormattedPrice(indexdata.tetkiklerinUcretToplami)} TL</div>
                                <div class="cardaciklama">TETKİKLERİN ÜCRET TOPLAMI</div></div>

                            <div class="adminCard  "><div class="cardNumber">{getFormattedPrice(indexdata.odenenUcretToplami)} TL</div>
                                <div class="cardaciklama">+ ÖDENEN ÜCRET TOPLAMI</div></div>

                            <div class="adminCard  "><div class="cardNumber">{getFormattedPrice(indexdata.tetkiklerinUcretToplami - indexdata.odenenUcretToplami)} TL</div>
                                <div class="cardaciklama">- ÖDENMEYEN ÜCRET TOPLAMI</div></div>
                        </div>

                    </div>}


                </div>



            </div>}
            {pageLoading && <div className="text-center">Yükleniyor...</div>}
        </div>
    )
}

export default Index



