import React, { useState, useEffect } from 'react'

import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap';
 
import axios from 'axios';


const HekimEkle = () => {

    let history = useHistory();

    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
                   headers: {
                       'Content-Type': 'application/json'
                       , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
                   }
               };

    const [pageLoading, setpageLoading] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [data, setdata] = useState([])




    useEffect(() => {

        //    , setpageLoading(true)
        axios.get("/webapi/uzmanlikListesi", headRequest).then(res => setdata(res.data));
        // .then(response => response.json())
        setpageLoading(true)


    }, [])



    const [uzmanlik, setuzmanlik] = useState(0);
    const [tel, settel] = useState('');
    const [tc, settc] = useState('');
    const [hekimAd, sethekimAd] = useState('');
    const [mail, setmail] = useState('');
    const [durum, setdurum] = useState(1);


    const submit = (e) => {

        e.preventDefault();


        if (uzmanlik == 0 || hekimAd.length < 2) {
            alert("Lütfen * işaretli alanları doldurun");
            return false;
        }


        const frdata = new FormData();

        frdata.append('uzmanlik', uzmanlik);
        frdata.append('tel', tel);
        frdata.append('tc', tc);
        frdata.append('hekimAd', hekimAd);
        frdata.append('mail', mail);
        frdata.append('durum', durum);




        axios.post("/webapi/Hekimolustur", frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {


                    history.push("hekimler")

                }
                else {
                    alert(response.statusText);

                }

                setIsLoading(false)
            }
        ).catch(error => alert(error),


            setIsLoading(false));




        // headRequest




    }






    return (

        <div>


            <div className="card-header card-header-primary"> 
            <Link to={"hekimler"} style={{ margin: "20px" }} >Geri Dön</Link>
                <h4 className="card-title">Hekim Ekle </h4>
            </div>

            <div className="card-body">
                <div className="row">





                    {pageLoading && <form className="form-horizontal" onSubmit={  submit}>




                        <div className="col-md-12">

                            <div className="form-group">
                                <label className="col-lg-3 control-label">Durum *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setdurum(e.target.value)} name="AnaSayfa" id="duum">
                                        <option value={1} selected={durum === 1} >Aktif</option>
                                        <option value={0} selected={durum === 0} >Pasif</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-3 control-label">Uzmanlık *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setuzmanlik(e.target.value)} name="uzmanlik" id="uzmanlik">

                                        <option selected={uzmanlik === 0} value={0}>-</option>

                                        {data.map(a =>
                                            <option value={a.id} selected={a.id === uzmanlik}>{a.uzmanlikAd}</option>
                                        )}

                                    </select>
                                </div>
                            </div>





                            <div className="form-group">
                                <label className="col-lg-3 control-label">Hekim Ad *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="hekimAd" name="hekimAd" className="form-control" onChange={e => sethekimAd(e.target.value)} value={hekimAd} />
                                </div>
                            </div>





                            <div className="form-group">
                                <label className="col-lg-3 control-label">Tc:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="tc" name="tc" className="form-control" onChange={e => settc(e.target.value)} value={tc} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Tel:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="tel" name="tel" className="form-control" onChange={e => settel(e.target.value)} value={tel} />
                                </div>
                            </div>



                            <div className="form-group">
                                <label className="col-lg-3 control-label">Mail:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="mail" name="mail" className="form-control" onChange={e => setmail(e.target.value)} value={mail} />
                                </div>
                            </div>








                        </div>



                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-right">


                                    {isLoading && <Button color="primary" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz </Button>}

                                    {!isLoading && <Button color="primary" type='submit'  >Ekle  </Button>}

                                </div>
                            </div>
                        </div>


                    </form>}

                    {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}

                </div>
            </div>

        </div>

    )

}

export default HekimEkle
