
export const getFormattedPrice = (price) => {
    try {
        return `${price.toFixed(2)}`;
    } catch (error) {
        return "-";
    }


};

export const getFormatDatetime = (date) => {

    try {
        // console.log("g :"+date);

        const datee = new Date(date);

        return datee.toLocaleDateString()

    } catch (error) {
        return "Hatalı Tarih."
    }

};

// const girisler = (JSON.parse(localStorage.getItem("ykyon")));

//  const headRequest = {
//                 headers: {
//                     'Content-Type': 'application/json'
//                     , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
//                 }
//             };

// export const headRequest  = {


// };


// export const klinikId = {
//     klinkId: girisler.klinkId
// };


// useEffect(() => {

//     axios.get('/webapi/tetkikListesi', headRequest).then((res) => {
//         console.log((res.data))
//         return (res.data);

//     }).catch(({ response }) => {

//         return response;

//     });
// }, [])