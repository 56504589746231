import React, { useState } from 'react'
import Navbar from './sideNav/navBar';
import Sidebar from './sideNav/sidebar';

import '../islemler/admin.css'

const Layout = ({ children }) => {

    const [closeLayer, setcloseLayer] = useState(false)

    return (
        <React.Fragment >

            <div className="AdminMAinControl wrapper">

                <div className={'close-layer ' + (closeLayer === true && ('clactive'))} onClick={() => (closeLayer === true ? setcloseLayer(false) : setcloseLayer(true))}> </div>

                <Sidebar closeLayer={closeLayer} />

                <div className="main-panel">
                    {/* Navbar */}
                    <Navbar closeLayer={closeLayer} setcloseLayer={setcloseLayer} />

                    {/* End Navbar */}
                    <div className="content">
                        <div className="container-fluid">
                            <div className="card">

                                {children}
                            </div>


                        </div>
                    </div>
                </div>


            </div>

        </React.Fragment >)

}


export default Layout;