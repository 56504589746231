import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faUser, faBell, faDesktop } from '@fortawesome/free-solid-svg-icons'



const Sidebar = ({ closeLayer }) => {



  // const value = localStorage.getItem("ykyon");

  // const girisler = JSON.parse(JSON.parse(value).payload.token);

  // const [count, setcount] = useState(0);

  // async function axiosFunc() {

  //   const requestOptionsyukle = {
  //     method: 'get', headers: { 'Content-Type': 'application/json', Authorization: 'Basic ' + encodeURI(girisler.kad + ":" + girisler.sifre) }
  //   };


  //   await fetch("/adn_api/iletisimCount", requestOptionsyukle).then(res => res.json()).then(
  //     (res) => {

  //       console.log(res);
  //       setcount(res)

  //     },
  //     () => {

  //       alert("Hata oluştu.");

  //     }

  //   ).catch(error => alert(error));

  // }

  // useEffect(() => {

  //   axiosFunc();
  // }, [])


  const girisler = (JSON.parse(localStorage.getItem("ykyon")));
  return (

    <div className={"sidebar " + (closeLayer === true && ('rightAc'))} data-color="purple" data-background-color="white" data-image="/images/logo.png">



      <div className="logo"><Link to="/index" className="simple-text logo-normal">
        <img src='/logo.jpg' alt="Logo" style={{ width: 200 }} />
        <div  > {girisler.klinik.toUpperCase()}</div>
        <div style={{ fontSize: "12px" }}> SürüM no : 1.0.50</div>

      </Link></div>


      {/* 

      <div className='mobilNavBar'>
        <ul className="navbar-nav">


          <li className="nav-item dropdown">
            <Link className="nav-link" to="/iletisim" >

              <FontAwesomeIcon className="material-icons" icon={faBell}> </FontAwesomeIcon>

              {count > 0 && <span className="notification">{count}</span>}

            </Link>

          </li>

          <li className="nav-item dropdown">
            <Link className="nav-link" to="/" target="_blank"    >
              <FontAwesomeIcon className="material-icons" icon={faDesktop}  > </FontAwesomeIcon>


            </Link>

          </li>



          <li className="nav-item dropdown">
            <Link className="nav-link" to="#" id="navbarDropdownProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon className="material-icons" icon={faUser}  > </FontAwesomeIcon>

              <p className="d-lg-none d-md-block">
                Account
              </p>
            </Link>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
              <Link className="dropdown-item" to="/kullGunc">Kullanıcı  Güncelle</Link>
              <Link className="dropdown-item" to="/sifreGunc">Şifre Güncelle</Link>
              <div className="dropdown-divider" />
              <Link className="dropdown-item" to="/logout">Çıkış</Link>
            </div>
          </li>

        </ul>

      </div> */}




      <div className="sidebar-wrapper">
        <ul className="nav">

          <li className="nav-item  ">
            <Link className="nav-link" to="/Randevular">
              <i className="material-icons"></i>
              <p> Randevular</p>
            </Link>
          </li>

          {/* <li className="nav-item  ">
            <Link className="nav-link" to="/Sevkler">
              <i className="material-icons"></i>
              <p>Sevkler</p>
            </Link>
          </li> */}



          <li className="nav-item  ">
            <Link className="nav-link" to="/Hastalar">
              <i className="material-icons"></i>
              <p>Hastalar</p>
            </Link>
          </li>


          <li className="nav-item  ">
            <Link className="nav-link" to="/Vezne">
              <i className="material-icons"></i>
              <p>Vezne</p>
            </Link>
          </li>


          <li className="nav-item  ">
            <Link className="nav-link" to="/Hekimler">
              <i className="material-icons"></i>
              <p>Hekimler</p>
            </Link>
          </li>

          <li className="nav-item  ">
            <Link className="nav-link" to="/Tetkikler">
              <i className="material-icons"></i>
              <p>Tetkikler</p>
            </Link>
          </li>


        </ul>
      </div>
    </div>
  )

}
export default Sidebar
