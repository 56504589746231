import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt, faCheck } from '@fortawesome/free-solid-svg-icons'

import { getFormatDatetime, getFormattedPrice } from '../../../func/funcColl';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';

const HastaSevkDetay = () => {


    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };
    const { id } = useParams();

    let history = useHistory();

    const [pageLoading, setpageLoading] = useState(false)

    const [hasta, sethasta] = useState({});
    const [TetkikTanim, setTetkikTanim] = useState([]);
    const [secilentetkikList, setsecilentetkikList] = useState([]);

    const [secilentetkikdali, setsecilentetkikdali] = useState(0)
    const [secilentetkik, setsecilentetkik] = useState(0)

    const [ucret, setucret] = useState(0)

    const [tetkikekle, settetkikekle] = useState(false);

    const [isleniyor, setisleniyor] = useState(false)
    const [showAciklamaGunc, setshowAciklamaGunc] = useState(false)

    const [aciklamaText, setaciklamaText] = useState('');
    const [aciklama, setaciklama] = useState('');
    const [dis, setdis] = useState('');


    const [showFaturaSil, setshowFaturaSil] = useState(false)
    const [showSevksil, setshowSevksil] = useState(false);
    const [showTetkiksil, setshowTetkiksil] = useState(false);

    const [secilenDoktor, setsecilenDoktor] = useState(0)

    const [secilenT, setsecilenT] = useState({})




    const [seciliodeme, setseciliodeme] = useState(0)
    const [seciliodemeid, setseciliodemeid] = useState(0)


    const handleClose = () => {
        setshowAciklamaGunc(false)
        setucret(0)
        setdis('')
        setaciklamaText('')
        setsecilenDoktor(0)
        settetkikekle(false)
        setaciklama(hasta.sevk.aciklama)
        setsecilentetkik(0)
        setshowSevksil(false)
        setshowTetkiksil(false)
        setsecilenT({})
        setshowFaturaSil(false);
    }

    useEffect(() => {


        console.clear();
        axios.get("/webapi/tetkikSevkList", headRequest).then(
            (res) => {
                // console.log(res.data)
                setTetkikTanim(res.data)

            }
        ).catch(error => alert(error))



        axios.get("/webapi/HastaSevklerDetay-" + id, headRequest).then(
            (res) => {

                console.log(res.data);
                sethasta(res.data)
                setaciklama(res.data.sevk.aciklama)

                // console.log(res.data.tetkikler)
                setpageLoading(true);

            }
        ).catch(error => alert(error))



    }, [])





    useEffect(() => {
        console.clear();
        // console.log(secilentetkikList)
        if (secilentetkik > 0) {
            try {


                setucret((secilentetkikList.filter((a) => Number(a.id) === Number(secilentetkik)))[0].ucreti);

            } catch {

            }
        }

    }, [secilentetkik])





    useEffect(() => {

        if (secilentetkikdali > 0) {
            try {


                setsecilentetkikList((TetkikTanim.filter((a) => Number(a.dal.id) === Number(secilentetkikdali)))[0].tetkiks);

            } catch {

            }
        }

    }, [secilentetkikdali])



    const tetkikSil = (e) => {

        setisleniyor(true)

        axios.post("/webapi/HastaTetkikSil-" + secilenT.stid, '', headRequest).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {

                    sethasta(response.data)

                    setaciklama(response.data.sevk.aciklama)
                    setpageLoading(true);


                }
                else {
                    alert(response.statusText);

                }
                handleClose()
                setisleniyor(false)
            }
        ).catch(error => alert(error), handleClose(),
            setisleniyor(false));

    }






    const sevkSil = (e) => {

        setisleniyor(true)

        axios.post("/webapi/HastaSevkSil-" + id, '', headRequest).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {

                    history.push("hasta-sevk-" + id)



                }
                else {
                    alert(response.statusText);

                }
                handleClose()
                setisleniyor(false)
            }
        ).catch(error => alert(error), handleClose(),
            setisleniyor(false));

    }


    const odemeiptalSec = (tutar, id) => {
        setseciliodeme(tutar)
        setseciliodemeid(id)
        setshowFaturaSil(true)
    }


    const odemeiptal = (a) => {

        setisleniyor(true)
        axios.post("/webapi/HastaFaturaiptal-" + seciliodemeid, "",headRequest).then(
            (res) => {

                console.log(res.data);
                sethasta(res.data)
                setaciklama(res.data.sevk.aciklama)

                // console.log(res.data.tetkikler)
                
                handleClose()
            }
        ).catch(error => alert(error),
        handleClose())


    }


    const silAcc = (a) => {
        console.clear()
        // console.log(a)
        setsecilenT(a);
        setshowTetkiksil(true);
    }


    const yeniTetkik = (e) => {


        setisleniyor(true)
        const frdata = new FormData();

        frdata.append('aciklama', aciklamaText);
        frdata.append('tetkik', secilentetkik);
        frdata.append('dis', dis);
        frdata.append('ucret', ucret);

        axios.post("/webapi/HastaTetkikOlustur-" + id, frdata, headRequest).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {

                    // console.log(response.data);
                    sethasta(response.data)

                    setaciklama(response.data.sevk.aciklama)


                }
                else {
                    alert(response.statusText);

                }
                handleClose()
                setisleniyor(false)
            }
        ).catch(error => alert(error), handleClose(),
            setisleniyor(false));



    }




    const sevkGuncelle = (e) => {

        setisleniyor(true)
        const frdata = new FormData();
        frdata.append('aciklama', aciklama);

        axios.post("/webapi/HastaSevkGuncelle-" + id, frdata, headRequest).then(
            (response) => {
                // console.log(response)
                if (response.status === 200) {

                    sethasta(response.data)

                    setaciklama(response.data.sevk.aciklama)
                    setpageLoading(true);


                }
                else {
                    alert(response.statusText);

                }
                handleClose()
                setisleniyor(false)
            }
        ).catch(error => alert(error), handleClose(),
            setisleniyor(false));

    }



    return (

        <div>

            <div className="card-header card-header-primary">
                <h4 className="card-title">Sevk Detayı</h4>
            </div>

            <div className="card-body">

                {pageLoading && <form className="form-horizontal" id="form" name="form" action="#">

                    <Link to={"hasta-sevk-" + hasta.hasta.hastaId} style={{ margin: "20px" }} >Geri Dön</Link>


                    <div className="row">

                        <div className="col-lg-2 col-md-2 col-sm-12 float-left">


                            <div className="form-group">
                                <div className="col-lg-12 text-center">

                                    <img src='/hasta.png' />

                                    <br />

                                </div>
                                <div><strong>{hasta.hasta.aciklama}</strong>  </div>



                            </div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12 float-left">
                            <div className="col-md-12">
                                <div>

                                    <div><strong>Ad Soyad</strong> : {hasta.hasta.ad.toUpperCase()} {hasta.hasta.soyad.toUpperCase()} </div>
                                    <div><strong>Tc</strong> : {hasta.hasta.tc} </div>

                                    <div><strong>Doğum Tarihi</strong> : {getFormatDatetime(hasta.hasta.dogumT)} </div>
                                    <div><strong>Telefon</strong> : {hasta.hasta.telefon} </div>


                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 float-left">



                            <div className="col-md-12"> <div className='form-group'>
                                <button type='button' className="btn btn-success" onClick={() => setshowAciklamaGunc(true)} >Güncelle</button>



                            </div>

                                <div className='form-group'>

                                    <button type='button' className="btn btn-danger" onClick={() => setshowSevksil(true)}  >Sil  </button></div>
                            </div>


                            <div style={{ margin: "10px" }} className='text-center'>
                                {hasta.fatura &&
                                    <>
                                        {hasta.fatura.length > 0 && hasta.fatura.map(a =>

                                            <div key={a.id} className='label label-success'> {getFormattedPrice(a.ucret)} TL   Ödendi <button type='button' onClick={() => odemeiptalSec(a.ucret, a.id)} className="btn btn-sm btn-success"> <FontAwesomeIcon icon={faCheck} /></button>
                                                <br />
                                            </div>

                                        )
                                        }
                                    </>
                                }
                                {hasta.kalanUcret > 0 && <>
                                    <hr></hr>
                                    <strong > Kalan Ücret: {getFormattedPrice(hasta.kalanUcret)} TL </strong>
                                    <Link className="btn btn-sm btn-danger" to={"Vezne"}>Vezneye Git</Link>
                                </>}
                            </div>



                        </div>


                    </div>

                    <div className='clearfix'></div>



                    <div className='row'>
                        <div className="col-md-12 table-responsive " style={{ marginTop: "50px" }}>


                            <h5 className='text-center'> {getFormatDatetime(hasta.sevk.tarih)}  Tarihli Sevk Detayı
                                <br />Hasta  {hasta.hasta.ad.toUpperCase()} {hasta.hasta.soyad.toUpperCase()}<br></br>
                                Hk. {hasta.hekim.hekimAd.toUpperCase()} </h5><br />
                            <div className='text-center'>
                                {hasta.sevk.aciklama}
                            </div>








                            <table className="table table-striped  ">
                                <thead className="">
                                    <tr>

                                        <th>
                                            Dal
                                        </th>
                                        <th>
                                            işlem
                                        </th>
                                        <th>
                                            Diş
                                        </th>

                                        <th>
                                            Açıklama
                                        </th>
                                        <th>
                                            Durum
                                        </th>
                                        <th>
                                            Ücret
                                        </th>
                                    </tr>

                                    <tr>
                                        <th colSpan='5' className='text-center'>


                                            <button type='button' className="btn btn-success" onClick={() => settetkikekle(true)} >Tetkik Ekle</button>


                                        </th>
                                    </tr>




                                </thead>

                                <tbody>{(hasta && hasta.tetkikler.length > 0) && hasta.tetkikler.map(a =>
                                    <tr key={a.stid}>
                                        <td>
                                            {a.dal}

                                        </td>
                                        <td>
                                            {a.ismi}
                                        </td>

                                        <td>
                                            {a.dis}
                                        </td>

                                        <td>
                                            {a.aciklama}
                                        </td>
                                        <td>
                                            {a.ucret} Tl
                                        </td>

                                        <td>
                                            <button type='button' className='btn btn-danger' onClick={() => silAcc(a)} >Sil</button>

                                        </td>
                                    </tr>)
                                }


                                </tbody>
                            </table>

                        </div>
                    </div>



                </form>}

                {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}


            </div>



            <Modal isOpen={tetkikekle}   >
                <ModalHeader toggle={handleClose}>Sevk Oluştur</ModalHeader>
                <ModalBody>

                    <div className="row">

                        <div className="col-md-12">

                            <div className='response'>

                                <h2>Hasta Adı : {pageLoading && <> {hasta.hasta.ad.toUpperCase()}</>} </h2>

                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Dal *:</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" onChange={e => setsecilentetkikdali(e.target.value)} name="secilentetkikdali" id="secilentetkikdali">

                                            <option selected={secilentetkikdali === 0} value={0}>-</option>

                                            {TetkikTanim.map(a =>
                                                <option key={a.dal.id} value={a.dal.id} selected={a.dal.id === secilentetkikdali}>{a.dal.dalAdi}</option>
                                            )}

                                        </select>
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label className="col-lg-3 control-label">Tetkik *:</label>
                                    <div className="col-lg-12">
                                        <select className="form-control" onChange={e => setsecilentetkik(e.target.value)} name="uzmanlik" id="uzmanlik">

                                            <option selected={secilentetkik === 0} value={0}>-</option>

                                            {secilentetkikList.map(a =>
                                                <option key={a.id} value={a.id} selected={a.id === secilentetkik}>{a.ismi}

                                                    :   {getFormattedPrice(a.ucreti)} TL</option>
                                            )}

                                        </select>
                                    </div>
                                </div>



                                <div>
                                    Diş
                                    <div className='form-group'>
                                        <input className='form-control' type='number' onChange={(e) => setdis(e.target.value)} value={dis} placeholder='Diş' ></input>
                                    </div>


                                </div>

                                <div>
                                    Açıklama
                                    <div className='form-group'>
                                        <textarea className='form-control' onChange={(e) => setaciklamaText(e.target.value)} value={aciklamaText} placeholder='Detay' ></textarea>
                                    </div>
                                </div>

                                <div>
                                    <div className='form-group'>
                                        Tetkik  Ücreti
                                        <input className='form-control' onChange={(e) => setucret(e.target.value)} value={ucret} placeholder='Ücret' ></input>
                                    </div>
                                </div>

                                {isleniyor && <div>
                                    İşleniyor...
                                </div>}




                            </div>
                            <div>


                            </div>

                        </div>

                    </div>

                </ModalBody>
                {!isleniyor && <ModalFooter>
                    {secilentetkik !== 0 && <Button color="success" onClick={yeniTetkik}>Ekle</Button>}
                    {secilentetkik === 0 && <span>Tekkik Seçin</span>}
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



            <Modal isOpen={showSevksil}>
                <ModalHeader toggle={handleClose}>Sevk Sil</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="col-lg-12 control-label">Sevk işlemini silmek istediginize emin misiniz?</label>

                        </div>

                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="danger" onClick={sevkSil}>Sil</Button>
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>








            <Modal isOpen={showTetkiksil}>
                <ModalHeader toggle={handleClose}>Tetkik Sil</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        {secilenT &&
                            <div className="form-group">
                                <label className="col-lg-12 control-label">{secilenT.dal} {secilenT.ismi} {secilenT.dis} işlemini silmek istediginize emin misiniz?</label>

                            </div>
                        }
                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="danger" onClick={() => tetkikSil()}>Sil</Button>
                    <Button color="secondary" onClick={() => handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



            <Modal isOpen={showAciklamaGunc}>
                <ModalHeader toggle={handleClose}>Açıklama Güncelle  </ModalHeader>
                <ModalBody>
                    <div className="col-md-12">

                        <div className="form-group">

                            <textarea placeholder='Açıklama' className='form-control' value={aciklama} onChange={(e) => setaciklama(e.target.value)}></textarea>



                        </div>

                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="success" onClick={() => sevkGuncelle()}>Güncelle</Button>
                    <Button color="secondary" onClick={() => handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



            <Modal isOpen={showFaturaSil}>
                <ModalHeader toggle={handleClose}>Ödeme İptal</ModalHeader>
                <ModalBody>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="col-lg-12 control-label">{seciliodeme} TL Ödemeyi  kaldırmak   istediginize emin misiniz?</label>

                        </div>

                    </div>
                </ModalBody>
                {!isleniyor && <ModalFooter>
                    <Button color="danger" onClick={odemeiptal}>Ödemeyi Sil</Button>
                    <Button color="secondary" onClick={handleClose}>İptal</Button>
                </ModalFooter>}

                {isleniyor && <ModalFooter>
                    İşleniyor...
                </ModalFooter>}
            </Modal>



        </div >

    )



}

export default HastaSevkDetay
