import React, { useState, useEffect } from 'react'

import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap';

// import { headRequest } from '../../../func/funcColl';
import axios from 'axios';


const HastaEkle = () => {

    let history = useHistory();



    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    const [isLoading, setIsLoading] = useState(false)

    const [data, setdata] = useState([])




    useEffect(() => {



    }, [])


    const [tc, settc] = useState('');
    const [ad, setad] = useState('');
    const [soyad, setsoyad] = useState('');

    let tmp = new Date(Date.now());

    const [dogumT, setdogumT] = useState(tmp.toISOString().split('T')[0]);
    const [adres, setadres] = useState('');
    const [anneAdi, setanneAdi] = useState('');
    const [babaAdi, setbabaAdi] = useState('');
    const [telefon, settelefon] = useState('');
    const [mail, setmail] = useState('');
    const [referans, setreferans] = useState('');

    const submit = (e) => {

        e.preventDefault();


        if (ad.length < 2 || soyad.length < 2 || tc.length < 10 || telefon.length < 5) {
            alert("Lütfen * işaretli alanları doldurun");
            return false;
        }

        const frdata = new FormData();

        frdata.append('tc', tc);
        frdata.append('ad', ad);
        frdata.append('soyad', soyad);
        frdata.append('dogumT', dogumT);
        frdata.append('adres', adres);
        frdata.append('anneAdi', anneAdi);
        frdata.append('babaAdi', babaAdi);
        frdata.append('telefon', telefon);
        frdata.append('mail', mail);
        frdata.append('referans', referans);



        axios.post("/webapi/HastaOlustur", frdata, headRequest).then(
            (response) => {
                console.log(response)
                if (response.status === 200) {


                    history.push("hasta-detay-"+response.data)

                }
                else {
                    alert(response.statusText);

                }

                setIsLoading(false)
            }
        ).catch(error => alert(error), 
            setIsLoading(false));

 
    }






    return (

        <div>


            <div className="card-header card-header-primary">
                <Link to={"hastalar"} style={{ margin: "20px" }} >Geri Dön</Link>
                <h4 className="card-title">Hasta Ekle </h4>
            </div>

            <div className="card-body">
                <div className="row">



                    <form className="form-horizontal" onSubmit={submit}>




                        <div className="col-md-12">







                            <div className="form-group">
                                <label className="col-lg-3 control-label">Ad *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="ad" name="ad" className="form-control" onChange={e => setad(e.target.value)} value={ad} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Soyad *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="soyad" name="soyad" className="form-control" onChange={e => setsoyad(e.target.value)} value={soyad} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Tc *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="tc" name="tc" className="form-control" onChange={e => settc(e.target.value)} value={tc} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Doğum Tarihi :</label>
                                <div className="col-lg-12">
                                    <input type="date" id="dogumT" name="dogumT" className="form-control" onChange={e => setdogumT(e.target.value)} value={dogumT} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Anne Adı :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="anneAdi" name="anneAdi" className="form-control" onChange={e => setanneAdi(e.target.value)} value={anneAdi} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Baba Adı :</label>
                                <div className="col-lg-12">
                                    <input type="text" id="babaAdi" name="babaAdi" className="form-control" onChange={e => setbabaAdi(e.target.value)} value={babaAdi} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Telefon *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="telefon" name="telefon" className="form-control" onChange={e => settelefon(e.target.value)} value={telefon} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Mail:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="mail" name="mail" className="form-control" onChange={e => setmail(e.target.value)} value={mail} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Adres:</label>
                                <div className="col-lg-12">
                                    <textarea type="text" id="adres" name="adres" className="form-control" onChange={e => setadres(e.target.value)} value={adres} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-lg-3 control-label">Referans:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="referans" name="referans" className="form-control" onChange={e => setreferans(e.target.value)} value={referans} />
                                </div>
                            </div>






                        </div>



                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-right">


                                    {isLoading && <Button color="primary" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz </Button>}

                                    {!isLoading && <Button color="primary" type='submit'  >Ekle  </Button>}

                                </div>
                            </div>
                        </div>


                    </form>



                </div>
            </div>

        </div>

    )

}

export default HastaEkle
