import React from 'react'
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux";
import { Route, Switch } from 'react-router';

import Index from './islemler/genel/index'
import Login from './loginOut/login'
import LogoutPage from './loginOut/logout'
import Forpassword from './loginOut/forpassword'
import Layout from './islemler/Layout';

import Hekimler from './islemler/genel/hekimler/hekimler';
import HekimEkle from './islemler/genel/hekimler/hekimEkle';
import HekimDetay from './islemler/genel/hekimler/hekimDetay';

import Randevu from './islemler/genel/randevular/randevu';

import Hastalar from './islemler/genel/hastalar/hastalar';

import Sevkler from './islemler/genel/sevkler/sevkler';

import Vezneler from './islemler/genel/vezne/vezneler';

import Tetkikler from './islemler/genel/tetkikler/tetkikler';
import TetkikDetay from './islemler/genel/tetkikler/tetkikDetay';
import HastaDetay from './islemler/genel/hastalar/hastaDetay';
import HastaEkle from './islemler/genel/hastalar/hastaEkle';
import HastaDetayGnc from './islemler/genel/hastalar/hastaDetayGnc';
import HastaSevkDetay from './islemler/genel/hastalar/hastaSevkDetay';
import Hastasevk from './islemler/genel/hastalar/hastasevk';

import SifeGunc from './islemler/genel/kullaniciIslemleri/SifeGunc'
// import KullGunc from './islemler/genel/kullaniciIslemleri/KullGunc'


const Indexroute = () => {




    const PrivateRoute = ({ component: Component, ...rest }) => {

        const loggedIn = useSelector(state => Boolean(state.user));

        // if (!loggedIn) {
        //     console.log("PrivateRoute: not logged in!")
        // }
        // else {
        //     console.log("PrivateRoute: logged in")
        // }

        return (
            <Route
                {...rest}
                render={props => (
                    !loggedIn ? (
                        <Redirect to={{
                            pathname: '/login', state: { from: props.location }
                        }} />
                    ) : (
                        <Layout> <Component {...props} /></Layout>
                    )
                )} />
        );
    };



    return (
        <Switch>
            <PrivateRoute exact path="/" component={Index} name="main"  ></PrivateRoute>
            <Route path="/login" component={Login}></Route>
            <Route path="/logout" component={LogoutPage}></Route>
            <Route path="/forpassword" component={Forpassword}></Route>

            <PrivateRoute exact path="/index" component={Index} name="main"  ></PrivateRoute>
            <PrivateRoute exact path="/Hekimler" component={Hekimler} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hekim-ekle" component={HekimEkle} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hekim-detay-:id" component={HekimDetay} name="main" ></PrivateRoute>

            <PrivateRoute exact path="/Hastalar" component={Hastalar} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hasta-ekle" component={HastaEkle} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hasta-detay-:id" component={HastaDetay} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hasta-detaygnc-:id" component={HastaDetayGnc} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hasta-sevk-:id" component={Hastasevk} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/hasta-sevkdetay-:id" component={HastaSevkDetay} name="main" ></PrivateRoute>

            <PrivateRoute exact path="/Sevkler" component={Sevkler} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/Randevular" component={Randevu} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/Vezne" component={Vezneler} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/Tetkikler" component={Tetkikler} name="main" ></PrivateRoute>
            <PrivateRoute exact path="/Tetkik-Detay-:id" component={TetkikDetay} name="main" ></PrivateRoute>

            <PrivateRoute exact path="/sifreGunc" name="main" component={SifeGunc}></PrivateRoute>
            {/* <PrivateRoute path="/kullGunc" component={KullGunc} ></PrivateRoute> */}

        </Switch>
    )
}

export default Indexroute
