import React, { useState, useEffect } from 'react'

import { Link, useHistory, useParams } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap';

 
import axios from 'axios';


const HekimDetay = () => {


    const girisler = (JSON.parse(localStorage.getItem("ykyon")));

    const headRequest = {
        headers: {
            'Content-Type': 'application/json'
            , Authorization: 'Basic ' + encodeURI(girisler.klinkId + ":" + girisler.token)
        }
    };

    let { id } = useParams();

    let history = useHistory();

    const [pageLoading, setpageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setdata] = useState([])




    const [uzmanlik, setuzmanlik] = useState(0);
    const [tel, settel] = useState('');
    const [tc, settc] = useState('');
    const [hekimAd, sethekimAd] = useState('');
    const [mail, setmail] = useState('');
    const [durum, setdurum] = useState(1);




    useEffect(() => {



        axios.get("/webapi/uzmanlikListesi", headRequest).then(res => setdata(res.data));
        // .then(response => response.json())

        axios.get("/webapi/Hekimdetay-" + id, headRequest).then(res => {

            console.clear();
            console.log(res)
            setuzmanlik(res.data.uzmanlik);
            settel(res.data.tel);
            settc(res.data.hekimTc);
            sethekimAd(res.data.hekimAd);
            setmail(res.data.mail)
            setdurum(res.data.durum?1:0)

        });




        setpageLoading(true)

    }, [])







    const submit = (e) => {

        setIsLoading(true)
        e.preventDefault();

        console.log("form")
        if (uzmanlik == 0 || hekimAd.length < 2) {
            alert("Lütfen * işaretli alanları doldurun");
            setIsLoading(false)
            return false;
        }


        const frdata = new FormData();

        frdata.append('uzmanlik', uzmanlik);
        frdata.append('tel', tel);
        frdata.append('tc', tc);
        frdata.append('hekimAd', hekimAd);
        frdata.append('mail', mail);
        frdata.append('durum', durum);


        axios.post("/webapi/HekimdetayGnc-" + id, frdata, headRequest).then(
            (res) => {
                console.log(res)
                if (res.status === 200) {

                    setuzmanlik(res.data.uzmanlik);
                    settel(res.data.tel);
                    settc(res.data.hekimTc);
                    sethekimAd(res.data.hekimAd);
                    setmail(res.data.mail)
                    setdurum(res.data.durum)
                    alert("Güncellendi.");
                }
                else {
                    alert(res.statusText);

                }

                setIsLoading(false)
            }
        ).catch(error => alert(error),


            setIsLoading(false));




    }



    return (


        <div>


            <div className="card-header card-header-primary">
                <Link to={"hekimler"} style={{ margin: "20px" }} >Geri Dön</Link>
                <h4 className="card-title">Hekim Detay </h4>
            </div>

            <div className="card-body">
                <div className="row">

                    {pageLoading && <form className="form-horizontal" onSubmit={submit}>

                        <div className="col-md-12">


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Durum *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setdurum(e.target.value)} name="durum" id="durum">
                                        <option value={1} selected={durum === 1} >Aktif</option>
                                        <option value={0} selected={durum === 0} >Pasif</option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-lg-3 control-label">Uzmanlık *:</label>
                                <div className="col-lg-12">
                                    <select className="form-control" onChange={e => setuzmanlik(e.target.value)} name="uzmanlik" id="uzmanlik">

                                        <option selected={uzmanlik === 0} value={0}>-</option>

                                        {data.map(a =>
                                            <option key={a.id} value={a.id} selected={a.id === uzmanlik}>{a.uzmanlikAd}</option>
                                        )}

                                    </select>
                                </div>
                            </div>





                            <div className="form-group">
                                <label className="col-lg-3 control-label">Hekim Ad *:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="hekimAd" name="hekimAd" className="form-control" onChange={e => sethekimAd(e.target.value)} value={hekimAd} />
                                </div>
                            </div>





                            <div className="form-group">
                                <label className="col-lg-3 control-label">Tc:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="tc" name="tc" className="form-control" onChange={e => settc(e.target.value)} value={tc} />
                                </div>
                            </div>


                            <div className="form-group">
                                <label className="col-lg-3 control-label">Tel:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="tel" name="tel" className="form-control" onChange={e => settel(e.target.value)} value={tel} />
                                </div>
                            </div>



                            <div className="form-group">
                                <label className="col-lg-3 control-label">Mail:</label>
                                <div className="col-lg-12">
                                    <input type="text" id="mail" name="mail" className="form-control" onChange={e => setmail(e.target.value)} value={mail} />
                                </div>
                            </div>




                        </div>



                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-right">





                                    {isLoading && <Button color="primary" disabled ><FontAwesomeIcon icon={faSyncAlt} spin /> Bekleyiniz </Button>}

                                    {!isLoading && <Button color="primary" >Güncelle  </Button>}

                                </div>
                            </div>
                        </div>


                    </form>}

                    {!pageLoading && <div style={{ textAlign: 'center' }}> <FontAwesomeIcon icon={faSyncAlt} spin /> Yükleniyor  </div>}

                </div>
            </div>

        </div>


    )
}

export default HekimDetay
